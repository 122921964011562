import { useMsal } from "@azure/msal-react";
import React from "react";

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };
  return <a className="dropdown-item" href="#" onClick={handleLogout}>Sign Out</a>;
};