import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../utils/constant";
import StageHeader from '../../components/StageHeader';
import { utils, writeFile } from 'xlsx';
import * as fields from "../../headerFields";
import * as Common from "../../utils/common";
const FetchCaseDetails = (props) => {
    const [items, setItems] = useState([]);
    const [issues, setIssues] = useState([]);
    const [issuesHeader, setIssuesHeader] = useState([]);
    const [html, setHTML] = useState('');
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        async function getCaseDetailsDetails() {
            setLoading(true);
            try {
                await axios.get(Constants._CASE_DETAILS_BY_CASE_ID_API, {
                    params: {
                        caseId: props.caseID,
                    }
                })
                    .then(async (response) => {
                        var data = response.data;
                        if (data.length > 0) {
                            setItems(data[0]);
                            var generatedHTML = Common.FormatAndHideData(data[0], fields.caseFields, fields.caseHideFields);
                            setHTML(generatedHTML);
                            console.log(data[0]["Case"]);
                            if (data[0]["Case"] !== null && data[0]["Case"] !== "")
                                //Get Issues
                                await axios.get(Constants._ISSUE_BY_CASE_ID_API, {
                                    params: {
                                        caseId: encodeURI(data[0]["Case"]),
                                    }
                                }).then((issueResponse) => {
                                    var issueData = issueResponse.data;
                                    if (issueData.length > 0) {
                                        setIssues(issueData);
                                        setIssuesHeader(Object.keys(issueData[0]));
                                    }
                                }).catch((error) => {
                                    console.log(error);
                                    alert(error);
                                })


                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        alert(error);
                    }).finally(
                        () => setLoading(false)
                    );


            } catch (error) {
                console.log("error", error);
                alert(error);
            }
        }
        // if (props.rebateID !== "")
        getCaseDetailsDetails();
    }, []);
    //Export to excel
    const handleExportToExcel = () => {
        try {
            let endpoints = [
                Constants._ISSUE_BY_CASE_NUMBER_REBATE_ID_API + '?rebateId=' + props.rebateID + "&caseNumber=" + encodeURI(items["Case_Number"])
            ];

            // Return our response in the allData variable as an array
            Promise.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
                axios.spread(({ data: issues }) => {
                    const wb = utils.book_new();
                    let isRecord = false
                    let casesData = Common.GetJsonArrayForOneItem(items);
                    if (casesData.length > 0) {
                        const casesWS = utils.json_to_sheet([]);
                        utils.sheet_add_json(casesWS, casesData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, casesWS, 'Case');
                        isRecord = true;
                    }
                    if (issues.length > 0) {
                        const issuesWS = utils.json_to_sheet([]);
                        let issuesData = Common.GetJsonArray(issues);
                        utils.sheet_add_json(issuesWS, issuesData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, issuesWS, 'Issues');
                        isRecord = true;
                    }
                    if (isRecord)
                        writeFile(wb, props.rebateID + '_CaseDetails.xlsx');

                })
            );

        } catch (error) {
            alert("Failed to get full Case Issue Details");
            console.log("error", error);
        }
    }

    return (
        <>
            {isLoading && <div className="containerloader"><div id="loader" class="loader"></div></div>}
            {!isLoading && (   <div id='printablediv'>
                <div>
                    <div className="CAT-Rebate-Banner">
                        <div className="top-rebate-banner-div">
                            <div className="top-left-banner-div">
                                <h2 className="rebate-h2-banner">{props.rebateID}</h2>
                            </div>
                            <div className="top-right-banner-div">
                                <button className="download-button" onClick={() => { handleExportToExcel(); }}>
                                    <img src={require('../../assets/images/downloadicon.svg').default} alt="Button Image" className="download-icon" />
                                </button>
                                <button className="download-button" onClick={Print}>
                                        <img src={require('../../assets/images/pdf.png')} alt="Button Image" className="download-icon" />
                                    </button>
                            </div>
                        </div>
                        <StageHeader rebateID={props.rebateID} />
                    </div>
                </div>
                <div>
                    <div className="rebate-head">
                        <h4>Case Details</h4>
                    </div>
                    <div>
                        <div className="underlinediv-24px">
                            <div className="th-underline"></div>
                        </div>
                        <div class="div-flex-column">
                            <h7>Issues:</h7>
                            {(issues.length > 0) ? (
                                <div className="tables-containers">
                                    <FormatTable theadData={issuesHeader} tbodyData={issues} rID={props.rebateID} />
                                </div>) : (<div><p2>No Issues Found</p2></div>)}
                        </div>


                        <div dangerouslySetInnerHTML={{ __html: html }} />
                    </div>

                </div>
            </div>)}
        </>
    )
}
function FormatTable({ theadData, tbodyData, rID }) {
    return (
        <table id="Invoice-Number" className="Calculation-table">
            <thead>
                <tr>
                    {theadData.map((heading, i) => {
                        return (heading == "Issue") ? (<></>) : (<th key={'ms-h-' + i}>{heading.replaceAll("_", " ")}</th>)
                    })}
                </tr>
            </thead>
            <tbody>
                {tbodyData.map((row, index) => {
                    return <tr key={index}>
                        {theadData.map((key, index) => {
                            return (
                                (key == "Issue") ? (<></>) ://Exclude the Issue ID Column
                                    ((key.toLowerCase() == "issue_id") ? (<td key={'ms-r-' + index}>
                                        <a href={'/IssueDetails?IssueId='
                                            + encodeURI(row['Issue'])
                                            + "&RebateId=" + rID} >{row[key]}</a>
                                    </td>)
                                        : (<td key={'ms-r-' + index}>{row[key]}</td>)))
                        })}
                    </tr>;
                })}
            </tbody>
        </table>
    )
}

export default FetchCaseDetails
const Print = () => {
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(false);
}