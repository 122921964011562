import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../utils/constant";
import StageHeader from '../../components/StageHeader';
import { utils, writeFile } from 'xlsx';
import * as fields from "../../headerFields";
import * as Common from "../../utils/common";
const FetchMeasureDetails = (props) => {
    //console.log(props);
    const [items, setItems] = useState([]);
    const [html, setHTML] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [measureInternalId, setMeasureInternalId] = useState('');
    const [extentedItems, setExtendedItems] = useState([]);
    useEffect(() => {
        async function getMeasureDetailsDetails() {
            setLoading(true);
            try {
                await axios.get(Constants._MESAURE_DETAILS_BY_MEASURE_ID_API, {
                    params: {
                        mesaureId: props.measureID,
                    }
                })
                    .then(async (response) => {
                        const result = []
                        var data = response.data;
                        if (data.length > 0) {
                            setItems(data[0]);
                            setMeasureInternalId(data[0]['Measure_ID']);
                            if (data[0]['Measure_ID'] !== '') {
                                try {
                                    await axios.get(Constants._EXTENDED_DETAILS_BY_MEASURE_ID_API, {
                                        params: {
                                            measureInternalId: data[0]['Measure_ID'],
                                        }
                                    })
                                        .then((response) => {
                                            var dataExtended = response.data;
                                            setExtendedItems(dataExtended);
                                            // if (Object.keys(response.data).length > 0) {
                                            //     var generatedHTML = Common.FormatMeasureData(data[0], dataExtended, fields.projectMesasureFields, props.rebateID, fields.projectMesasureHideFields);
                                            // }
                                            // else {
                                            var generatedHTML = Common.FormatMeasureData(data[0], dataExtended, fields.projectMesasureFields, props.rebateID, fields.projectMesasureHideFields);
                                            // }
                                            setHTML(generatedHTML);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            alert(error);
                                        }).finally(
                                            () => setLoading(false)
                                        );

                                } catch (error) {
                                    console.log("error", error);
                                    alert(error);
                                }
                            }
                            else {
                                var generatedHTML = Common.FormatMeasureData(data[0], extentedItems, fields.projectMesasureFields, props.rebateID, fields.projectMesasureHideFields);
                                setHTML(generatedHTML);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        alert(error);
                    }).finally(
                        () => setLoading(false)
                    );


            } catch (error) {
                console.log("error", error);
                alert(error);
            }
        }
        getMeasureDetailsDetails();
    }, []);
    //Export to excel
    const handleExportToExcel = () => {
        try {
            if (items !== null) {
                const wb = utils.book_new();
                const ws = utils.json_to_sheet([]);
                let exlItems = Common.GetJsonArrayForOneItem(items);
                utils.sheet_add_json(ws, exlItems, { origin: 'A1', skipHeader: true });
                utils.book_append_sheet(wb, ws, 'MeasureDetails');
                writeFile(wb, props.rebateID + "_" + props.measureID + '_MeasureDetails.xlsx');
            }
        }
        catch (error) {
            console.log("error", error);
        }
    }

    return (
        <>
            {isLoading && <div className="containerloader"><div id="loader" class="loader"></div></div>}
            {!isLoading && (<div id='printablediv'>
                <div>
                    <div className="CAT-Rebate-Banner">
                        <div className="top-rebate-banner-div">
                            <div className="top-left-banner-div">
                                <h2 className="rebate-h2-banner">{props.rebateID}</h2>
                            </div>
                            <div className="top-right-banner-div">
                                <button className="download-button" onClick={() => { handleExportToExcel(); }}>
                                    <img src={require('../../assets/images/downloadicon.svg').default} alt="Button Image" className="download-icon" />
                                </button>
                                <button className="download-button" onClick={Print}>
                                    <img src={require('../../assets/images/pdf.png')} alt="Button Image" className="download-icon" />
                                </button>
                            </div>
                        </div>
                        <StageHeader rebateID={props.rebateID} />
                    </div>
                </div>
                <div>
                    <div className="rebate-head">
                        <h4>Measure Details</h4>
                    </div>
                    <div>
                        <div className="underlinediv-24px">
                            <div className="th-underline"></div>
                        </div>

                        <div dangerouslySetInnerHTML={{ __html: html }} />
                    </div>

                </div>
            </div>)}
        </>
    )

}

export default FetchMeasureDetails
const Print = () => {
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(false);
}