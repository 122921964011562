import LeftNavigation from "../../components/LeftNavigation"
import BreadCrumb from "../../components/BreadCrumb"
import './../../assets/css/style.css'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import CatalystLoginPage from '../CatalystLoginPage'
import FetchActivityDetails from "./fetchActivityDetails";


export const ActivityDetails = () => {
    const queryParameters = new URLSearchParams(window.location.search)
    const rebateId = decodeURI(queryParameters.get("RebateId"));
    const activityId = decodeURI(queryParameters.get("ActivityId"));
    const activityType = decodeURI(queryParameters.get("ActivityType"));
    return (
        <div>
            <AuthenticatedTemplate>
                <div className="container-content">
                    <BreadCrumb RebateID={rebateId} />
                    <div className="sidenav-bar-div">
                        <LeftNavigation />
                        <div className="right-content">
                            <FetchActivityDetails rebateID={rebateId} activityID={activityId} activityType={activityType} />
                        </div>
                    </div>
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <CatalystLoginPage />
            </UnauthenticatedTemplate>
        </div>
    );
}
export default ActivityDetails;