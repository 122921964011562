import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../utils/constant";



const FetchActivity= (props) => {
    const [items, setItems] = useState([]);
    const [header, setHeader] = useState([]);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        getActivity();
    }, []);

    async function getActivity() {
        try {
            setLoading(true);
            await axios.get(Constants._ALL_ACTIVITIES_API, {
                params: {
                    rebateId: props.rebateID,
                }
            })
                .then((response) => {
                    const result = []
                    var data = response.data;
                    if (data.length > 0) {
                        setItems(data);
                        setHeader(Object.keys(data[0]));
                        props.setActivitysData(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                }).finally(
                    () => setLoading(false)
                );

        } catch (error) {
            console.log("error", error);
        }
    }
    return (
        <>
            {isLoading && <div className="containerloader"><div id="loader" class="loader"></div></div>}
            {!isLoading && (
                (items.length > 0) ? (
                    <div className="tables-containers">
                        <FormatTable theadData={header} tbodyData={items} rID={props.rebateID} />
                    </div>) : (<div><p2>No Activitys Found</p2></div>)

            )}
        </>
    )

}

function FormatTable({ theadData, tbodyData, rID }) {
    return (
        <table id="Activity_Calculations" className="Calculation-table">
            <thead>
                <tr>
                    {theadData.map((heading, i) => {
                        return (heading == "Activity" || heading == "Activity_Type_ID") ? (<></>) : (<th key={'ms-h-' + i}>{heading.replaceAll("_", " ")}</th>)// || heading == "Activity_Type"
                    })}
                </tr>
            </thead>
            <tbody>
            {tbodyData.map((row, index) => {
                    return <tr key={index}>
                        {theadData.map((key, index) => {
                            return (
                                (key == "Activity" || key == "Activity_Type_ID") ? (<></>) ://|| key == "Activity_Type" Exclude the Issue ID Column
                                    (key.toLowerCase() == "subject") ? (<td key={'ms-r-' + index}>
                                        <a href={'/ActivityDetails?ActivityId='
                                    + encodeURI(row['Activity'])
                                    + "&RebateId=" + rID
                                    + "&ActivityType=" + encodeURI(row['Activity_Type_ID'])} >{row[key]}</a>
                                    </td>)
                                        : (<td key={'ms-r-' + index}>{row[key]}</td>))
                        })}
                    </tr>;
                })}
            </tbody>
        </table>
    )
}
export default FetchActivity