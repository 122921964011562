import LeftNavigation from "../../components/LeftNavigation"
import BreadCrumb from "../../components/BreadCrumb"
import './../../assets/css/style.css'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import CatalystLoginPage from '../CatalystLoginPage'
import FetchCase from "./fetchCase"
import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../utils/constant"
import { utils, writeFile } from 'xlsx';
import * as Common from "../../utils/common";

export const Case = () => {
    const queryParameters = new URLSearchParams(window.location.search)
    const rebateId = decodeURI(queryParameters.get("RebateId"));
    const [caseData, setCaseData] = useState([]);
    const [items, setItems] = useState([]);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        async function getRebateDetails() {
            try {
                setLoading(true);
                await axios.get(Constants._REBATE_HEADER_API, {
                    params: {
                        rebateId: rebateId,
                    }
                })
                    .then((response) => {
                        const result = []
                        var data = response.data;
                        setItems(data);
                        if (data.length > 0) {
                            setItems(data[0]);
                            //console.log(data[0]);
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            } catch (error) {
                console.log("error", error);
            }
        }

        // if (props.rebateID !== "")
        getRebateDetails();
    }, []);
    //Export to excel
    const handleExportToExcel = () => {

        try {
            let endpoints = [
                Constants._All_CASE_BY_REBATE_API + '?rebateId=' + rebateId,
                Constants._ALL_ISSUE_BY_REBATE_ID_API + '?rebateId=' + rebateId,
            ];

            // Return our response in the allData variable as an array
            Promise.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
                axios.spread(({ data: cases }, { data: issues }) => {
                    let isRecord = false;
                    const wb = utils.book_new();
                    if (cases.length > 0) {
                        const casesWS = utils.json_to_sheet([]);
                        let casesData = Common.GetJsonArray(cases);
                        utils.sheet_add_json(casesWS, casesData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, casesWS, 'Case');
                        isRecord = true;
                    }
                    if (issues.length > 0) {
                        const issuesWS = utils.json_to_sheet([]);
                        let issuesData = Common.GetJsonArray(issues);
                        utils.sheet_add_json(issuesWS, issuesData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, issuesWS, 'Issues');
                        isRecord = true;
                    }
                    if (isRecord)
                        writeFile(wb, rebateId + '_Case_Issue.xlsx');

                })
            );

        } catch (error) {
            alert("Failed to get full Case Issue Details");
            console.log("error", error);
        }
    }
    return (
        <div>
            <AuthenticatedTemplate>
                <div className="container-content">
                    <BreadCrumb RebateID={rebateId} />
                    <div className="sidenav-bar-div">
                        <LeftNavigation />
                        <div className="right-content">
                            <div id='printablediv'>
                                <div>
                                    <div className="CAT-Rebate-Banner">
                                        <div className="top-rebate-banner-div">
                                            <div className="top-left-banner-div">
                                                <h2 className="rebate-h2-banner">{rebateId}</h2>
                                            </div>
                                            <div className="top-right-banner-div">
                                                <button className="download-button" onClick={() => { handleExportToExcel(); }}>
                                                    <img src={require('../../assets/images/downloadicon.svg').default} alt="Button Image" className="download-icon" />
                                                </button>
                                                <button className="download-button" onClick={Print}>
                                                    <img src={require('../../assets/images/pdf.png')} alt="Button Image" className="download-icon" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="bottom-rebate-banner-div">
                                            <div className="bottom-rebate-bottomleft-div">
                                                <h6>STATUS:</h6><p1>{items["Status"]}</p1>
                                            </div>
                                            <div className="bottom-rebate-bottomleft-div">
                                                <h6>STAGE:</h6><p1>{items["Stage"]}</p1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="rebate-head">
                                        <h4>Case</h4>
                                    </div>
                                    <div className="underlinediv-24px">
                                        <div className="th-underline"></div>
                                    </div>
                                    <FetchCase rebateID={rebateId} setCaseData={setCaseData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <CatalystLoginPage />
            </UnauthenticatedTemplate>
        </div>
    );
}
export default Case;
const Print = () => {
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(false);
}