import * as React from 'react';
export const BreadCrumb = (props) => {
    return (
        <div className="home-audit-div">
            <h5>Home</h5>
            <img src={require('././../assets/images/rightarrow.svg').default} style={{ marginLeft:'8px', marginRight: '8px'}} />
            <h5  style={{ color:'#D63827'}}>
                {props.RebateID}
            </h5>
        </div>)
}
export default BreadCrumb;
