import React, { useEffect, useState } from "react";
import SearchResult from "./searchresult";

const RebateFilter = () => {
    const [filterType, setfilterType] = useState('RebateID');
    const [searchText, setSearchText] = useState('');
    const [isReady, setReady] = useState(false);
    function handleSelectFilterTypeChange(event) {
        setfilterType(event.target.value);
        setReady(false);
    }
    function handleSearchChange(event) {
        setSearchText(event.target.value);
        setReady(false);
    }
    function handleClick(event) {
        event.preventDefault();
        if (!searchText.trim())
            alert("Search box cannot be empty.")
        else
            setReady(true);
    }
    const handleKeypress = e => {
        //it triggers by pressing the enter key
      if (e.keyCode === 13) {
        handleClick(e);
      }
    };
    return (
        <>
            <div className="filter-search">
                <div className="filter-dropdown">
                    <select value={filterType} onChange={handleSelectFilterTypeChange} className="category-filter" >
                        {/* <option value="All" className="option-filter">All</option> */}
                        <option value="RebateID" className="option-filter">Rebate ID</option>
                        <option value="Account" className="option-filter">Account</option>
                        <option value="Contact" className="option-filter">Contact</option>
                        <option value="Program" className="option-filter">Program</option>
                        <option value="Project" className="option-filter">Project</option>
                        <option value="Location" className="option-filter">Location</option>
                    </select>
                </div>
                <div className="search-bar">
                    <input type="text" id="search-input" placeholder="Type to search ..." className="searchinput"
                        value={searchText} required
                        onChange={handleSearchChange}   onKeyUp={handleKeypress}/>
                    <button id="search-button" className="searchbtn" onClick={handleClick} >Search</button>
                </div>
                
            </div>
            <div className="search-message"><i>Please use <b>%</b>  as the wildcard character before or after your search criteria.</i></div>
            <SearchResult searchString={searchText.trim()} filterInfo={filterType} ready={isReady} />
        </>

    )
}
export default RebateFilter