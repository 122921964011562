import * as React from 'react';
import { HeadProvider, Title, Link, Meta } from 'react-head';
import '../../assets/css/style.css'
import { useMsal } from "@azure/msal-react";
import { loginApiRequest } from "../../authConfig"
import { SignInO365 } from '../../components/SignIn'
import { SignInButton } from '../../components/signInButton';
import '../../../src/assets/css/style.css'
export const CatalystLoginPage = () => {
    return (
        <div>
            {/* <HeadProvider>
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" />
            </HeadProvider> */}
            <div className="form-container">
                <div className="container">
                    <div className="row gx-lg-5 align-items-center">
                        <div className="leftdiv">
                            <div className="centerdiv">
                                <h1>
                                    Catalyst <span style={{ fontWeight: 500 }}>Archive Tool</span>
                                </h1>
                                <div className="divunderline">
                                    <div className="underline"></div>
                                </div>
                              
                            </div>
                        </div>
                        <div className="col-lg-6 mb-5 mb-lg-0 ">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="formcenterdiv">
                                            <img src={require('../../assets/images/Redlogo.svg').default} alt="Logo" width="150" height="25px" />
                                        </div>
                                        <div className="formcenterdiv">
                                            <h2 style={{ height: '48px' }}>Log In </h2>
                                        </div>
                                        <div className="form-group">
                                            <div className="logindiv">
                                                <SignInO365 />
                                                {/* <div className="forgot-password">
                                                    <a href="#" onclick="">Forgot Password?</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.1.0/js/bootstrap.bundle.min.js"></script>
            <script src="https://code.jquery.com/jquery-2.2.4.min.js" integrity="sha256-BbhdlvQf/xTY9gja0Dq3HiwQF8LaCRTXxZKRutelT44="
             crossOrigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>
            <script src='https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/js/bootstrap.min.js'></script>
        </div>

    );
}
export default CatalystLoginPage;
