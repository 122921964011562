import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './components/app';
import { Landing } from './pages/Landing';
import reportWebVitals from './reportWebVitals';
import { useMsal, MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginApiRequest } from "./authConfig";
import axios from "axios";
import { BrowserRouter as Router } from 'react-router-dom';
import CatalystLoginPage from "../src/pages/CatalystLoginPage"
const root = ReactDOM.createRoot(document.getElementById('root'));
const msalInstance = new PublicClientApplication(msalConfig);
//Axios Time out for All Request
//axios.defaults.timeout = 2000;

//axios.defaults.baseURL = "https://localhost:7286/api/";
// console.log(process.env);
// console.log(process.env.REACT_APP_API_URL);
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(
  async (response) => {
    const account = msalInstance.getAllAccounts()[0];
    const msalResponse = await msalInstance.acquireTokenSilent({
      ...loginApiRequest,
      account: account,
    });
    response.headers["Authorization"] = `Bearer ${ msalResponse.accessToken }`;
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);
root.render(
  //<React.StrictMode>
    <MsalProvider instance={msalInstance} >
      <Router>
        <AuthenticatedTemplate>
          <Landing  />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <CatalystLoginPage />
        </UnauthenticatedTemplate>
      </Router>
    </MsalProvider>
 //</React.StrictMode>

);

reportWebVitals();