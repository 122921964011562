import React, { useEffect, useState, useRef } from "react";

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import CatalystLoginPage from '../CatalystLoginPage'
import DatePicker from "react-datepicker";
import AllUsersDropDown from '../AuditLog/AllUsersDropDown';
// import AuditDetails from "./AuditDetails";
import UserLogs from "./UserLogs";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import * as Constants from "./../../utils/constant";
import { useNavigate } from "react-router-dom";
export const AuditLog = () => {
    const ref = useRef();
    const [fromDate, setfromDate] = useState(subtractDays(new Date(), 7));
    const [tillDate, settillDate] = useState(new Date());
    const [selectedUser, setSelectedUsers] = useState(0);
    useEffect(() => {
        async function isAdmininstrator() {
            try {
                await axios.get(Constants._IS_CATALYSTADMIN)
                    .then((response) => {
                        var data = response.data;
                        console.log(data);
                        if (data === false)
                            RedirectToAcessDeniedPage();
                       
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            } catch (error) {
                console.log("error", error);
            }
        }
        isAdmininstrator();
    }, []);
    const navigate = useNavigate();
    const RedirectToAcessDeniedPage = () => {

       navigate("/AccessDenied");
    }
    useEffect(() => {
        async function getFromDate() {
            ref.current.getLog();
        }
        getFromDate();
    }, [])
    const getSelectedUser = (userId) => {
        setSelectedUsers(userId);
    };
    return (
        <div>
            <AuthenticatedTemplate>
                <div id='printablediv'>
                    <div className="tab-pane fade show active" id="content1" role="tabpanel" aria-labelledby="tab1">
                        <div className="tabshead">
                            <h4>Audit Log</h4>
                        </div>
                        <div className="underlinediv">
                            <div className="th-underline"></div>
                        </div>
                        <div className="audit-div">
                            <div className="filter-row">
                                <div className="filter-row">
                                    <div className="filter-row-c1">
                                        <div className="filter-column">
                                            <label for="filter-label">From Date</label>
                                            <DatePicker selected={fromDate} className="daterange-input"
                                                onChange={(d) => setfromDate(d)} required form="external-form"
                                                maxDate={tillDate} />
                                        </div>
                                    </div>
                                    <div className="filter-row-c1">
                                        <div className="filter-column">
                                            <label for="filter-label">Till Date</label>
                                            <DatePicker selected={tillDate} className="daterange-input"
                                                onChange={(date) => settillDate(date)} required
                                                maxDate={tillDate} />
                                        </div>
                                    </div>
                                    <div className="filter-row-c1">
                                        <div className="filter-column">
                                            <label for="filter-label">User name</label>
                                            <AllUsersDropDown getSelectedUser={getSelectedUser} />
                                        </div>
                                    </div>

                                    {/* <div className="filter-row-c1">
                                    <div className="filter-column">
                                        <label for="filter-label">Search</label>
                                        <div className="search-bar">
                                            <div className="search-container-audit">
                                                <input type="text" id="searchuser-role" name="Search" className="searchbar-audit" placeholder="Search" />
                                                <img className="search-icon-audit" src={require('../../assets/images/searchicon.svg').default}></img>
                                            </div>
                                        </div>
                                    </div>

                                </div> */}

                                    <div className="filter-row-c1 auditBtn">
                                        <button onClick={() => ref.current.getLog()} className="button-submit audit-button">Get Logs</button>
                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                <div className="csv-dwnld-btn-div">
                                    <button id="csv-download-btn" className="csv-download-btn">
                                        Excel
                                        <img src={require('../../assets/images/downloadicon.svg').default} alt="Button Image" className="csvicon" />
                                    </button>
                                </div>
                            </div> */}
                        </div>
                        <UserLogs ref={ref} fromDate={fromDate} tillDate={tillDate} selectedUser={selectedUser} />
                    </div>
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <CatalystLoginPage />
            </UnauthenticatedTemplate>
        </div >

    );
}
function subtractDays(date, days) {
    date.setDate(date.getDate() - days);

    return date;
}

// const getAllUsers = useCallback(() =>{
//     try {
//       axios.get("/User/GetAllUsersName")
//             .then((response) => {
//                 const allUsersName = response.data;
//                 console.log(response.data);
//                 return allUsersName;
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     } catch (error) {
//         console.log("error", error);
//     }
// });

export default AuditLog;