import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../utils/constant";
import StageHeader from '../../components/StageHeader';
import { utils, writeFile } from 'xlsx';
import * as Common from "../../utils/common";


const FetchRebateDocuments = (props) => {
    //console.log(props);
    const [items, setItems] = useState([]);
    const [html, setHTML] = useState('');
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        async function getMeasureDetailsDetails() {
            setLoading(true);
            try {
                await axios.get(Constants._STORAGE_DOCUMENTS_API, {
                    params: {
                        rebateId: encodeURI(props.rebateID),
                    }
                })
                    .then((response) => {
                        const result = []
                        var data = response.data;
                        if (data.length > 0) {
                            setItems(data);
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        alert(error);
                    }).finally(
                        () => setLoading(false)
                    );;;

            } catch (error) {
                console.log("error", error);
                alert(error);
            }
        }
        getMeasureDetailsDetails();
    }, []);

    const axiosDownloadFile = (fileUrl, fileName) => {
        return axios({
            url: Constants._STORAGE_FILE + encodeURI(fileUrl),
            method: 'GET',
            responseType: 'blob',
        })
            .then(response => {
                const href = window.URL.createObjectURL(response.data);
                const anchorElement = document.createElement('a');
                anchorElement.href = href;
                anchorElement.download = fileName;
                document.body.appendChild(anchorElement);
                anchorElement.click();
                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            })
            .catch(error => {
                alert('Error in downloading the file.')
                console.log('error: ', error);
            });
    }
    //Export to excel
    const handleDownloadZip = async (rebateId) => {
        try {
            if (items.length > 0) {
                let allFiles = [];
                items.map((m) => {
                    allFiles.push(m.Uri)
                });
                const files = JSON.stringify(allFiles);
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: Constants._STORAGE_DOWNLOADZIP_API + "?rebateId=" + rebateId,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: files,
                    responseType: 'blob'
                };
                return await axios.request(config)
                    .then(response => {
                        var binaryData = [];
                        binaryData.push(response.data);
                        const href = window.URL.createObjectURL(new Blob(binaryData, { type: "application/zip" }))
                        //  const href = window.URL.createObjectURL(response.data);
                        const anchorElement = document.createElement('a');
                        anchorElement.href = href;
                        anchorElement.download = rebateId + ".zip";
                        document.body.appendChild(anchorElement);
                        anchorElement.click();
                        document.body.removeChild(anchorElement);
                        window.URL.revokeObjectURL(href);
                    })
                    .catch(error => {
                        alert('Error in downloading the file.')
                        console.log('error: ', error);
                    });
            }
            else {
                alert('No files for download');
            }
        } catch (error) {
            console.log("error", error);
            alert(error);
        }
    }


    return (
        <>
            {isLoading && <div className="containerloader"><div id="loader" class="loader"></div></div>}
            {!isLoading && (   <div id='printablediv'>
                <div>
                    <div className="CAT-Rebate-Banner">
                        <div className="top-rebate-banner-div">
                            <div className="top-left-banner-div">
                                <h2 className="rebate-h2-banner">{props.rebateID}</h2>
                            </div>
                            <div className="top-right-banner-div">
                                <button className="download-button" onClick={() => { handleDownloadZip(props.rebateID); }}>
                                    <img src={require('../../assets/images/downloadicon.svg').default} alt="Button Image" className="download-icon" />
                                </button>
                                <button className="download-button" onClick={Print}>
                                        <img src={require('../../assets/images/pdf.png')} alt="Button Image" className="download-icon" />
                                    </button>
                            </div>
                        </div>
                        <StageHeader rebateID={props.rebateID} />
                    </div>
                </div>
                <div>
                    <div className="rebate-head">
                        <h4>Documents</h4>
                    </div>
                    <div>
                        <div className="underlinediv-24px">
                            <div className="th-underline"></div>
                        </div>
                        {(items.length > 0) ? (
                            <table id="Documents-table" class="Documents-table">
                                <tr>
                                    <th>Document Name</th>
                                    <th>Folder Path</th>
                                    <th>Last Modified</th>
                                    <th> Action</th>
                                </tr>
                                {items.map((m, i) => {
                                    return (<tr>
                                        <td>{m.Name}</td>
                                        <td>{m.FolderPath} </td>
                                        <td>{m.LastModified} </td>
                                        <td>
                                            <button class="downld-btn" onClick={() => { axiosDownloadFile(m.Uri,m.Name); }}>
                                                <img src={require('../../assets/images/icon-download.svg').default} alt="Button Image" />
                                                <span class="dwnld-txt">Download</span>
                                            </button>
                                        </td>
                                    </tr>)
                                })}
                            </table>) :
                            (<div><p2>No Documents Found.</p2></div>)
                        }
                    </div>
                </div>
            </div>)}
        </>
    )
}


export default FetchRebateDocuments
const Print = () => {
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(false);
}