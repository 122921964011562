import { useMsal } from "@azure/msal-react";
import { loginApiRequest } from '../authConfig'

export const SignInO365 = () => {
    const { instance } = useMsal();

    const handleLogin = async () => {
        await instance.loginRedirect(loginApiRequest).catch((e) => {
            console.log(e);
        });
    };
    return <a className="button-submit" onClick={handleLogin}>Log In</a>;
}; 
