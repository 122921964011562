
import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../utils/constant";
const AllUsersDropDown = (props) => {
  const [items, setItems] = React.useState([]);
  const [selectedItemId, setSelectedItemId] = React.useState([]);

  React.useEffect(() => {
    async function UserNamesOfEmployee() {
      try {
        axios.get(Constants._GET_LOGS_USER)
          .then((response) => {
            setItems(response.data.map((m) => ({ label: m.UserName, value: m.UserId })));
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log("error", error);
      }
    }
    UserNamesOfEmployee();
  }, []);
  const setUser = (u) => {
    setSelectedItemId(u);
    props.getSelectedUser(u);
  }
  return (
    <select className="dropdown-select userdropdown"
      onChange={e => setUser(e.target.value)}>
      <option key={0} value={0} >
        {"--All---"}
      </option>
      {items.map(item => (
        <option key={item.value} value={item.value} >
          {item.label}
        </option>
      ))}
    </select>
  )
}

export default AllUsersDropDown

