import React, { useState } from "react";
import TopDisplayBar from "../../components/TopDisplayBar"
import Banner from "../../components/Banner"
import TopNavigation from "../../components/TopNavigation"
import LeftNavigation from "../../components/LeftNavigation"
import BreadCrumb from "../../components/BreadCrumb"
import Home from '../Home'
import CatalystLoginPage from '../CatalystLoginPage';
import { CatalystContext } from "../../context/catalystContext";

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";

export const Landing = () => {
    const [rebateHeader, setRebateHeader] = useState([]);
    return (
        <div>
            <AuthenticatedTemplate>
                <CatalystContext.Provider value={[rebateHeader, setRebateHeader]}>
                    <TopDisplayBar />
                    <Banner />
                    <TopNavigation />
                </CatalystContext.Provider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <CatalystLoginPage />
            </UnauthenticatedTemplate>
        </div>
    )
}

export default Landing