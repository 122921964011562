import React, { useEffect, forwardRef, useImperativeHandle, useRef } from "react"
import DataTableGrid from './DataTableGrid';
import axios from "axios";
import { isEditable } from "@testing-library/user-event/dist/utils";
import * as Constants from "../../utils/constant";
import { utils, writeFile } from 'xlsx';
import * as Common from "../../utils/common";
import moment from "moment/moment";

const UserLogs = forwardRef((props, ref) => {
    const [items, setItems] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true);
    useImperativeHandle(ref, () => ({
        async getLog() {
            //console.log("child function");
            await getUserLogs();
        }
    }));
    async function getUserLogs() {
        try {

            if (props.fromDate != null && props.tillDate != null) {
                setLoading(true)
                await axios.get(Constants._GET_USER_LOGS + "?fromDate=" + props.fromDate.toLocaleDateString() + "&tillDate=" + props.tillDate.toLocaleDateString() + "&selectedUserId=" + props.selectedUser)
                    .then((response) => {
                        const result = []
                        var data = response.data;
                        for (var i = 0; i < data.length; i++) {
                            var val = data[i];
                            var def = Object.keys(val);
                            result.push([val[def[3]], val[def[4]], val[def[6]], IsEnabled(val[def[4]]), val[def[5]], formatDate(val[def[2]])]);
                        }
                        //console.log(result);
                        setItems(result);

                    })
                    .catch((error) => {
                        console.log(error);
                    }).finally(
                        () => setLoading(false)
                    );
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    const handleExportToExcel = () => {

        try {
            if (items !== null) {
                //console.log(items);
                let fileName = "From_" + moment(props.fromDate).format("MM_DD_yyyy") + "_To_" + moment(props.tillDate).format("MM_DD_yyyy")
                    + '_AuditLog.xlsx'
                let Heading = [['FirstName', 'Last Name', 'Business Unit', 'User Status', 'Rebate ID', 'Rebate Viewed On']];
                //Had to create a new workbook and then add the header
                const wb = utils.book_new();
                const ws = utils.json_to_sheet([]);
                utils.sheet_add_aoa(ws, Heading);
                //Starting in the second row to avoid overriding and skipping headers
                utils.sheet_add_json(ws, items, { origin: 'A2', skipHeader: true });
                utils.book_append_sheet(wb, ws, 'AuditLog');
                writeFile(wb, fileName);
            }
        }
        catch (error) {
            console.log("error", error);
        }
    }
    return (
        <>
            <div>
                <div className="audit-btn-div">
                    <button id="csv-download-btn" className="audit-btn" onClick={() => { handleExportToExcel(); }}>
                        Excel
                        <img src={require('../../assets/images/downloadicon.svg').default} alt="Button Image" className="csvicon" />
                    </button>
                </div>
            </div>
            <div className="audit-grid">
                {isLoading && <ul className="ul-table"><div className="containerloader"><div id="loader" class="loader"></div></div></ul>}
                {!isLoading && (
                    <ul className="ul-table">
                        <DataTableGrid props={items} />
                    </ul>
                )}
            </div>
        </>
    )
});

export default UserLogs;
function formatDate(d) {
    const date = new Date(d);
    // return date.toLocaleString('en-US');
    return date;
}
function IsEnabled(isEnabled) {
    if (isEnabled)
        return 'Active';
    else
        return "In Active";
}