import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import * as Constants from "../utils/constant";
//import { CatalystContext } from "../context/catalystContext";
import { useNavigate } from "react-router-dom";



export const RebateStage = (props) => {
    //  const [rebateHeader, setRebateHeader] = useContext(CatalystContext);
    const [items, setItems] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [isAuthorize, setAuthorize] = useState(true);
    const navigate = useNavigate();
   const  RedirectToAcessDeniedPage = () =>{
      
        navigate("/AccessDenied");
    }
    useEffect(() => {
        async function getRebateDetails() {
            try {
                await axios.get(Constants._REBATE_HEADER_API, {
                    params: {
                        rebateId: props.rebateID,
                    }
                })
                    .then((response) => {
                        const result = []
                        var data = response.data;
                        setItems(data);
                        if (data.length > 0) {
                            setItems(data[0]);
                            //console.log(data[0]);
                        }
                        else {
                            setAuthorize(false)
                            RedirectToAcessDeniedPage();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            } catch (error) {
                console.log("error", error);
            }
        }

        // if (props.rebateID !== "")
        getRebateDetails();
    }, []);
    if (isLoading) {
        return (
            <div className="bottom-rebate-banner-div">
                <div className="bottom-rebate-bottomleft-div">
                    <h6>STATUS : </h6>
                    <p1>{items["Status"]}</p1>
                </div>
                <div className="bottom-rebate-bottomleft-div">
                    <h6>STAGE : </h6>
                    <p1>{items["Stage"]}</p1>
                </div>

            </div>

        )
    }
}

export default RebateStage;