
export const GetJsonArray = (d) => {

    var d = d.filter(function (x) {
        return (x !== (undefined || null || ''));
    });



    var result = [];
    var header = Object.keys(d[0]);
    let headerWithSpace = [];
    header.forEach(function (key) {
        headerWithSpace.push(key.replaceAll("_", " "));
    });
    result.push(headerWithSpace);
    for (let i = 0; i < d.length; i++) {
        result.push(Object.values(d[i]));
    }

    return result;
}
export const GetJsonArrayMeasures = (d) => {

    var d = d.filter(function (x) {
        return (x !== (undefined || null || ''));
    });
    var result = [];
    var header = Object.keys(d[0]);
    let headerWithSpace = [];
    header.forEach(function (key) {
        if (key.replaceAll("_", " ").toLowerCase() == "incentive total"
            || key.replaceAll("_", " ").toLowerCase() == "kwh incentive"
            || key.replaceAll("_", " ").toLowerCase() == "mcf incentive"
            || key.replaceAll("_", " ").toLowerCase() == "kW incentive"
            || key.replaceAll("_", " ").toLowerCase() == "gallons incentive"
            || key.replaceAll("_", " ").toLowerCase() == "therm incentive"
            || key.replaceAll("_", " ").toLowerCase() == "therm demand incentive") {

            headerWithSpace.push(key.replaceAll("_", " ") + " $");
        }
        else {
            headerWithSpace.push(key.replaceAll("_", " "));
        }
    });
    result.push(headerWithSpace);
    for (let i = 0; i < d.length; i++) {
        result.push(Object.values(d[i]));
    }

    return result;
}

export const GetJsonArrayForOneItem = (d) => {
    //filtering the NUll & Empty Items
    var result = [];
    const entries = Object.entries(d) // 1️
    const nonEmptyOrNull = entries.filter(([key, val]) => val !== '' && val !== null) // 2️
    const outputArray = Object.fromEntries(nonEmptyOrNull) // 3️
    let sortedArray = sortObject(outputArray);
    //Get Header
    var header = Object.keys(sortedArray);

    //Remove Underscores
    let headerWithSpace = [];
    header.forEach(function (key) {
        headerWithSpace.push(key.replaceAll("_", " "));
    });
    result.push(headerWithSpace);
    //Get Data
    // let val=[];
    // header.forEach(function(key) {
    //     val.push(outputArray[key]);
    // });
    // result.push(val);
    result.push(Object.values(sortedArray))

    return result;
}

export const FormatDataForActivity = (data, fields, hideFields) => {
    var html = '<div class="div-flex-column">';
    var keyItemsJson = {};
    var otherItemsJson = {};

    for (var colName in data) {
        if (fields.includes(colName)) {
            keyItemsJson[colName.replaceAll("_", " ").trim()] = data[colName];
        }
        else {
            otherItemsJson[colName.replaceAll("_", " ").trim()] = data[colName];
        }

    }
    keyItemsJson = sortObject(keyItemsJson);
    otherItemsJson = sortObject(otherItemsJson);
    if (fields.length != 0) {
        html += "<div  class='sub-header'>Key Fields</div>";
        for (var keyItem in keyItemsJson) {
            if (!hideFields.includes(keyItem)) {
                if (!keyItem.startsWith('x', 0)) {
                    var itemValue = keyItemsJson[keyItem];
                    if (itemValue !== null && itemValue !== "") {

                        html += "<div class='detail1'><div class='detail1-left'><h7>" + keyItem.replaceAll("_", " ")
                            + " : </h7></div><div class='detail1-right'><p2>" + itemValue + "</p2></div></div>"

                    }
                }
            }
        }


        html += "<div class='sub-header'>Other Fields </div>";
    }
    for (var otherItem in otherItemsJson) {
        if (!hideFields.includes(otherItem)) {
            if (!otherItem.startsWith('x', 0)) {
                var itemValue = otherItemsJson[otherItem];
                if (itemValue !== null && itemValue !== "")
                    if (otherItem.replaceAll("_", " ").toLowerCase() == "activity type") {
                        html += "<div class='detail1'><div class='detail1-left'><h7>" + otherItem.replaceAll("_", " ")
                            + " : </h7></div><div class='detail1-right'><p2>" + getActivityName(itemValue) + "</p2></div></div>";
                    }
                    else {
                        html += "<div class='detail1'><div class='detail1-left'><h7>" + otherItem.replaceAll("_", " ")
                            + " : </h7></div><div class='detail1-right'><p2>" + itemValue + "</p2></div></div>"
                    }
            }
        }
    }
    html += "</div>";
    return html;
}
function getActivityName(activityType) {
    let type = '';
    switch (activityType) {
        case 4201:
            type = 'Appointment';
            break;
        case 4202:
            type = 'Email';
            break;
        case 4204:
            type = 'Fax';
            break;
        case 4206:
            type = 'Case Resolution';
            break;
        case 4207:
            type = 'Letter';
            break;
        case 4208:
            type = 'Opportunity Close';
            break;
        case 4209:
            type = 'Order Close';
            break;
        case 4210:
            type = 'Phone Call';
            break;
        case 4211:
            type = 'Quote Close';
            break;
        case 4212:
            type = 'Task';
            break;
        case 4214:
            type = 'Service Activity';
            break;
        case 4251:
            type = 'Recurring Appointment';
            break;
        case 4401:
            type = 'Campaign Response';
            break;
        case 4402:
            type = 'Campaign Activity';
            break;
        case 4406:
            type = 'Bulk Operation';
            break;
        default:
            type = 'Unknown Activity';
            break;
    }

    return type;
}
//Format Html
export const FormatData = (data, fields) => {
    var html = '<div class="div-flex-column">';
    var keyItemsJson = {};
    var otherItemsJson = {};

    for (var colName in data) {
        if (fields.includes(colName)) {
            keyItemsJson[colName.replaceAll("_", " ").trim()] = data[colName];
        }
        else {
            otherItemsJson[colName.replaceAll("_", " ").trim()] = data[colName];
        }

    }
    keyItemsJson = sortObject(keyItemsJson);
    otherItemsJson = sortObject(otherItemsJson);
    if (fields.length != 0) {
        html += "<div  class='sub-header'>Key Fields</div>";
        for (var keyItem in keyItemsJson) {
            if (!keyItem.startsWith('x', 0)) {
                var itemValue = keyItemsJson[keyItem];
                if (itemValue !== null && itemValue !== "")
                    html += "<div class='detail1'><div class='detail1-left'><h7>" + keyItem.replaceAll("_", " ")
                        + " : </h7></div><div class='detail1-right'><p2>" + itemValue + "</p2></div></div>"
            }
        }


        html += "<div class='sub-header'>Other Fields </div>";
    }
    for (var otherItem in otherItemsJson) {
        if (!otherItem.startsWith('x', 0)) {
            var itemValue = otherItemsJson[otherItem];
            if (itemValue !== null && itemValue !== "")
                html += "<div class='detail1'><div class='detail1-left'><h7>" + otherItem.replaceAll("_", " ")
                    + " : </h7></div><div class='detail1-right'><p2>" + itemValue + "</p2></div></div>"
        }
    }
    html += "</div>";
    return html;
}
export const FormatAndHideData = (data, fields, hideFields) => {
    var html = '<div class="div-flex-column">';
    var keyItemsJson = {};
    var otherItemsJson = {};
    for (var colName in data) {
        if (fields.includes(colName)) {
            keyItemsJson[colName.replaceAll("_", " ").trim()] = data[colName];
        }
        else {
            otherItemsJson[colName.replaceAll("_", " ").trim()] = data[colName];
        }

    }
    keyItemsJson = sortObject(keyItemsJson);
    otherItemsJson = sortObject(otherItemsJson);
    html += "<div  class='sub-header'>Key Fields</div>";
    for (var keyItem in keyItemsJson) {
        if (!keyItem.startsWith('x', 0)) {
            var itemValue = keyItemsJson[keyItem];
            if (!hideFields.includes(keyItem)) {

                // console.log(keyItem);
                if (itemValue !== null && itemValue !== "") {
                    html += "<div class='detail1'><div class='detail1-left'><h7>" + keyItem.replaceAll("_", " ")
                        + " : </h7></div><div class='detail1-right'><p2>" + itemValue + "</p2></div></div>"
                }
            }
        }
    }

    html += "<div class='sub-header'>Other Fields </div>";
    for (var otherItem in otherItemsJson) {
        if (!otherItem.startsWith('x', 0)) {
            if (!hideFields.includes(otherItem)) {
                var itemValue = otherItemsJson[otherItem];
                if (itemValue !== null && itemValue !== "") {
                    html += "<div class='detail1'><div class='detail1-left'><h7>" + otherItem.replaceAll("_", " ")
                        + " : </h7></div><div class='detail1-right'><p2>" + itemValue + "</p2></div></div>"
                }
            }
        }
    }
    html += "</div>";
    return html;

}
export const FormatProjectData = (data, extentedItems, fields, rbtId, hideFields) => {
    console.log(data);
    var html = '<div class="div-flex-column">';
    var keyItemsJson = {};
    var otherItemsJson = {};
    for (var colName in data) {
        if (fields.includes(colName)) {
            keyItemsJson[colName.replaceAll("_", " ").trim()] = data[colName];
        }
        else {
            otherItemsJson[colName.replaceAll("_", " ").trim()] = data[colName];
        }

    }
    keyItemsJson = sortObject(keyItemsJson);
    otherItemsJson = sortObject(otherItemsJson);
    html += "<div  class='sub-header'>Key Fields</div>";

    if (Object.keys(extentedItems).length > 0) {
        html += "<div class='detail1'><div class='detail1-left'><h7>"
            + "Extented Project : </h7></div><div class='detail1-right'><a href='/ProjectExtendedDetails?RebateId="
            + rbtId + "&ProjectGuid=" + keyItemsJson["Project"] + "'>Click here</a></div></div>"
    }

    for (var keyItem in keyItemsJson) {
        if (!hideFields.includes(keyItem) && !keyItem.startsWith('x', 0)) {
            var itemValue = keyItemsJson[keyItem];
            if (itemValue !== null && itemValue !== "") {
                html += "<div class='detail1'><div class='detail1-left'><h7>" + keyItem.replaceAll("_", " ")
                    + " : </h7></div><div class='detail1-right'><p2>" + itemValue + "</p2></div></div>"

            }
        }
    }

    html += "<div class='sub-header'>Other Fields </div>";
    for (var otherItem in otherItemsJson) {
        if (!hideFields.includes(otherItem) && !otherItem.startsWith('x', 0)) {
            var itemValue = otherItemsJson[otherItem];
            if (itemValue !== null && itemValue !== "") {
                html += "<div class='detail1'><div class='detail1-left'><h7>" + otherItem.replaceAll("_", " ")
                    + " : </h7></div><div class='detail1-right'><p2>" + itemValue + "</p2></div></div>"
            }
        }
    }
    html += "</div>";
    return html;
}
export const FormatMeasureData = (data, extentedItems, fields, rbtId, hideFields) => {
    var html = '<div class="div-flex-column">';
    var keyItemsJson = {};
    var otherItemsJson = {};
    for (var colName in data) {
        if (fields.includes(colName)) {
            keyItemsJson[colName.replaceAll("_", " ").trim()] = data[colName];
        }
        else {
            otherItemsJson[colName.replaceAll("_", " ").trim()] = data[colName];
        }

    }
    keyItemsJson = sortObject(keyItemsJson);
    otherItemsJson = sortObject(otherItemsJson);
    html += "<div  class='sub-header'>Key Fields</div>";
    if (keyItemsJson["Efficient Measure"] !== "" && keyItemsJson["Efficient Measure"] !== null) {
        html += "<div class='detail1'><div class='detail1-left'><h7>"
            + "Efficient Measure : </h7></div><div class='detail1-right'><a href='/EfficientMeasure?RebateId="
            + rbtId + "&MeasureGuid=" + keyItemsJson["Efficient Measure"] + "'>Click here</a></div></div>"
    }
    if (keyItemsJson["Base Measure"] !== "" && keyItemsJson["Base Measure"] !== null) {
        html += "<div class='detail1'><div class='detail1-left'><h7>"
            + "Base Measure : </h7></div><div class='detail1-right'><a href='/BaseMeasure?RebateId="
            + rbtId + "&MeasureGuid=" + keyItemsJson["Base Measure"] + "'>Click here</a></div></div>"
    }
    if (Object.keys(extentedItems).length > 0) {
        html += "<div class='detail1'><div class='detail1-left'><h7>"
            + "Extented Measure : </h7></div><div class='detail1-right'><a href='/ExtentedMeasure?RebateId="
            + rbtId + "&MeasureGuid=" + keyItemsJson["Measure ID"] + "'>Click here</a></div></div>"
    }
    if (keyItemsJson["Contractor"] !== "" && keyItemsJson["Contractor"] !== null) {
        html += "<div class='detail1'><div class='detail1-left'><h7>"
            + "Contractor : </h7></div><div class='detail1-right'><a href='/Contractor?RebateId="
            + rbtId + "&ContractorId=" + keyItemsJson["Contractor ID"] + "'>" + keyItemsJson["Contractor"] + "</a></div></div>"
    }

    for (var keyItem in keyItemsJson) {
        if (!hideFields.includes(keyItem) && !keyItem.startsWith('x', 0)) {
            var itemValue = keyItemsJson[keyItem];
            if (itemValue !== null && itemValue !== "") {
                if (keyItem.replaceAll("_", " ").toLowerCase() == "incentive total"
                    || keyItem.replaceAll("_", " ").toLowerCase() == "kwh incentive"
                    || keyItem.replaceAll("_", " ").toLowerCase() == "mcf incentive"
                    || keyItem.replaceAll("_", " ").toLowerCase() == "kW incentive"
                    || keyItem.replaceAll("_", " ").toLowerCase() == "gallons incentive"
                    || keyItem.replaceAll("_", " ").toLowerCase() == "therm incentive"
                    || keyItem.replaceAll("_", " ").toLowerCase() == "therm demand incentive")
                    html += "<div class='detail1'><div class='detail1-left'><h7>" + keyItem.replaceAll("_", " ")
                        + " : </h7></div><div class='detail1-right'><p2>" + itemValue + " $ </p2></div></div>"
                else
                    html += "<div class='detail1'><div class='detail1-left'><h7>" + keyItem.replaceAll("_", " ")
                        + " : </h7></div><div class='detail1-right'><p2>" + itemValue + "</p2></div></div>"

            }
        }
    }

    html += "<div class='sub-header'>Other Fields </div>";
    for (var otherItem in otherItemsJson) {
        if (!hideFields.includes(otherItem) && !otherItem.startsWith('x', 0)) {
            var itemValue = otherItemsJson[otherItem];
            if (itemValue !== null && itemValue !== "") {
                if (otherItem.replaceAll("_", " ").toLowerCase() == "incentive total"
                    || otherItem.replaceAll("_", " ").toLowerCase() == "kwh incentive"
                    || otherItem.replaceAll("_", " ").toLowerCase() == "mcf incentive"
                    || otherItem.replaceAll("_", " ").toLowerCase() == "kW incentive"
                    || otherItem.replaceAll("_", " ").toLowerCase() == "gallons incentive"
                    || otherItem.replaceAll("_", " ").toLowerCase() == "therm incentive"
                    || otherItem.replaceAll("_", " ").toLowerCase() == "therm demand incentive")
                    html += "<div class='detail1'><div class='detail1-left'><h7>" + otherItem.replaceAll("_", " ")
                        + " : </h7></div><div class='detail1-right'><p2>" + itemValue + " $ </p2></div></div>"
                else
                    html += "<div class='detail1'><div class='detail1-left'><h7>" + otherItem.replaceAll("_", " ")
                        + " : </h7></div><div class='detail1-right'><p2>" + itemValue + "</p2></div></div>"
            }
        }
    }
    html += "</div>";
    return html;
}




function sortObject(obj) {
    return Object.keys(obj).sort().reduce(function (result, key) {
        result[key] = obj[key];
        return result;
    }, {});
}