
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import * as Constants from "../../utils/constant";
import FetchMesaureCalulations from "../MeasureCalculations/fetchmesaurecalulations";
import FetchInvoiceDetails from "../Invoice/fetchInvoiceDetails";
import FetchActivity from "../Activity/fetchActivity";
import FetchCaseDetails from "../Case/fetchCase";
import { utils, writeFile } from 'xlsx';
import * as Common from "../../utils/common";
import StageHeader from '../../components/StageHeader';
//import { CatalystContext } from "../../context/catalystContext";

const FetchRebate = (props) => {
    //const [rebateHeader, setRebateHeader] = useContext(CatalystContext);
    const [items, setItems] = useState([]);
    const [html, setHTML] = useState('');
    const [isLoading, setLoading] = useState(true);
    const [measuresData, setMeasuresData] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const [caseData, setCaseData] = useState([]);
    const [activitysData, setActivitysData] = useState([]);
    const [notes, setNotes] = useState([]);
    const [notesHeader, setNotesHeader] = useState([]);
    const [rebateInTakeID, setRebateInTakeID] = useState();
    useEffect(() => {
        console.log('Inside FetchRebate');
        async function getRebateDetails() {
            try {
                await axios.get(Constants._REBATE_HOME_BY_ID_API, { //Constants._REBATE_BY_ID_API,
                    params: {
                        rebateId: props.rebateID,
                    }
                })
                    .then(async (response) => {
                        const result = []
                        var data = response.data;

                        setItems(data);
                        if (data.length > 0) {
                            setItems(data[0]);
                            setRebateInTakeID(data[0]["Rebate_InTake_ID"]);
                            if (data[0]["Rebate_InTake_ID"] !== '')
                                await axios.get(Constants._REBATE_NOTES_BY_REBATE_ID_API, {
                                    params: {
                                        rebateIntakeId: data[0]["Rebate_InTake_ID"],
                                    }
                                })
                                    .then((notesResponse) => {
                                        var notesData = notesResponse.data;
                                        if (notesData.length > 0) {
                                            setNotes(notesData)
                                            setNotesHeader(Object.keys(notesData[0]));
                                        }

                                    }).catch((error) => {
                                        console.log(error);
                                        alert(error);
                                    });

                            setLoading(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        alert(error);
                    });

            } catch (error) {
                console.log("error", error);
                alert(error);
            }
        }

        // if (props.rebateID !== "")
        getRebateDetails();
    }, []);

    // useEffect(() => {
    async function handleExportToExcel(rbtId) {

        try {
           
            let endpoints = [
                Constants._REBATE_BY_ID_API + '?rebateId=' + rbtId,
                Constants._REBATE_NOTES_BY_REBATE_ID_API + '?rebateIntakeId=' + encodeURI(rebateInTakeID),
                Constants._ACCOUNT_BY_REBATE_ID_API + '?rebateId=' + rbtId,
                Constants._CONTACT_BY_REBATE_ID_API + '?rebateId=' + rbtId,
                Constants._LOCATION_BY_REBATE_ID_API + '?rebateId=' + rbtId,
                Constants._PROJECT_BY_REBATE_ID_API + '?rebateId=' + rbtId,
                Constants._UTILITY_ACCOUNT_BY_REBATE_ID_API + '?rebateId=' + rbtId,
                Constants._PREMISE_BY_REBATE_ID_API + '?rebateId=' + rbtId,
                Constants._All_MESAURE_BY_REBATE_API + '?rebateId=' + rbtId,
                Constants._EXPORT_BASE_MESASURE_BY_REBATE_API + '?rebateId=' + rbtId,
                Constants._EXPORT_EFFICENT_MESASURE_BY_REBATE_API + '?rebateId=' + rbtId,
                Constants._EXPORT_EXTENTED_MESASURE_BY_REBATE_API + '?rebateId=' + rbtId,
                Constants._All_INVOICE_BY_REBATE_API + '?rebateId=' + rbtId,
                Constants._All_CASE_BY_REBATE_API + '?rebateId=' + rbtId,
                Constants._ALL_ISSUE_BY_REBATE_ID_API + '?rebateId=' + rbtId,
                //  Constants._ACTIVITIES_BY_REBATE_ID_API + '?rebateId=' + rbtId,


            ];

            // Return our response in the allData variable as an array
            Promise.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
                axios.spread(({ data: rebate }, { data: notesDetails }, { data: account }, { data: contact }, { data: location },
                    { data: project }, { data: utility }, { data: premises }, { data: measeure }, { data: baseMeaseure }
                    , { data: efficentMeaseure }, { data: extendedMeasure }, { data: invoice },
                    { data: cases }, { data: issues }) => {
                    console.log({ rebate, account, contact, location, utility, premises, measeure, baseMeaseure, efficentMeaseure, invoice, cases });
                    const wb = utils.book_new();
                    if (rebate.length > 0) {
                        const rebateWS = utils.json_to_sheet([]);
                        let rebateData = Common.GetJsonArrayForOneItem(rebate[0]);
                        utils.sheet_add_json(rebateWS, rebateData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, rebateWS, 'Rebate');
                    }
                    if (notesDetails.length > 0) {
                        console.log(notesDetails);
                        console.log(measeure);
                        const notesDetailsWS = utils.json_to_sheet([]);
                        let notesDetailsData = Common.GetJsonArray(notesDetails);
                        utils.sheet_add_json(notesDetailsWS, notesDetailsData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, notesDetailsWS, 'Notes');
                    }
                    if (account.length > 0) {
                        const accountWS = utils.json_to_sheet([]);
                        let accountData = Common.GetJsonArrayForOneItem(account[0]);
                        utils.sheet_add_json(accountWS, accountData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, accountWS, 'Account');
                    }
                    if (contact.length > 0) {
                        const contactWS = utils.json_to_sheet([]);
                        let contactData = Common.GetJsonArrayForOneItem(contact[0]);
                        utils.sheet_add_json(contactWS, contactData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, contactWS, 'Contact');
                    }
                    if (location.length > 0) {
                        const locationWS = utils.json_to_sheet([]);
                        let locationData = Common.GetJsonArrayForOneItem(location[0]);
                        utils.sheet_add_json(locationWS, locationData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, locationWS, 'Location');
                    }
                    if (project.length > 0) {
                        const projectWS = utils.json_to_sheet([]);
                        let projectData = Common.GetJsonArrayForOneItem(project[0]);
                        utils.sheet_add_json(projectWS, projectData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, projectWS, 'Project');
                    }
                    if (utility.length > 0) {
                        const utilityWS = utils.json_to_sheet([]);
                        let utilityData = Common.GetJsonArrayForOneItem(utility[0]);
                        utils.sheet_add_json(utilityWS, utilityData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, utilityWS, 'Utility');
                    }
                    if (premises.length > 0) {
                        const premisesWS = utils.json_to_sheet([]);
                        let premisesData = Common.GetJsonArrayForOneItem(premises[0]);
                        utils.sheet_add_json(premisesWS, premisesData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, premisesWS, 'Premises');
                    }
                    if (measeure.length > 0) {
                        const measeureWS = utils.json_to_sheet([]);
                        let measeureData = Common.GetJsonArray(measeure);
                        utils.sheet_add_json(measeureWS, measeureData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, measeureWS, 'Measures');
                    }
                    if (baseMeaseure.length > 0) {
                        const baseMeaseureWS = utils.json_to_sheet([]);
                        let baseMeaseureData = Common.GetJsonArray(baseMeaseure);
                        utils.sheet_add_json(baseMeaseureWS, baseMeaseureData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, baseMeaseureWS, 'Base Measures');
                    }
                    if (efficentMeaseure.length > 0) {
                        const efficentMeaseureWS = utils.json_to_sheet([]);
                        let efficentMeaseureData = Common.GetJsonArray(efficentMeaseure);
                        utils.sheet_add_json(efficentMeaseureWS, efficentMeaseureData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, efficentMeaseureWS, 'Efficent Measures');
                    }
                    if (extendedMeasure.length > 0) {
                        const extendedMeasureWS = utils.json_to_sheet([]);
                        let extendedMeasureData = Common.GetJsonArray(extendedMeasure);
                        utils.sheet_add_json(extendedMeasureWS, extendedMeasureData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, extendedMeasureWS, 'Extended Measures');
                    }
                    if (invoice.length > 0) {
                        const invoiceWS = utils.json_to_sheet([]);
                        let invoiceData = Common.GetJsonArray(invoice);
                        utils.sheet_add_json(invoiceWS, invoiceData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, invoiceWS, 'Invoice');
                    }
                    if (cases.length > 0) {
                        const casesWS = utils.json_to_sheet([]);
                        let casesData = Common.GetJsonArray(cases);
                        utils.sheet_add_json(casesWS, casesData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, casesWS, 'Case');
                    }
                    if (issues.length > 0) {
                        const issuesWS = utils.json_to_sheet([]);
                        let issuesData = Common.GetJsonArray(issues);
                        utils.sheet_add_json(issuesWS, issuesData, { origin: 'A1', skipHeader: true });
                        utils.book_append_sheet(wb, issuesWS, 'Issues');
                    }

                    writeFile(wb, props.rebateID + '_Full_Details.xlsx');

                })
            );

        } catch (error) {
            alert("Failed to get full Rebate Details");
            console.log("error", error);
        }
    }
    // }, []);
    if (isLoading) {
        return (
            <div id='printablediv'>
                <div>
                    <div className="CAT-Rebate-Banner">
                        <div className="top-rebate-banner-div">
                            <div className="top-left-banner-div">
                                <h2 className="rebate-h2-banner">{props.rebateID}</h2>
                            </div>
                            <div className="top-right-banner-div">
                                <button className="download-button" onClick={() => { handleExportToExcel(props.rebateID) }} >
                                    <img src={require('../../assets/images/downloadicon.svg').default} alt="Button Image" className="download-icon" />
                                </button>
                                <button className="download-button" onClick={Print}>
                                        <img src={require('../../assets/images/pdf.png')} alt="Button Image" className="download-icon" />
                                    </button>
                            </div>
                        </div>
                        {/* <div className="bottom-rebate-banner-div">
                            <div className="bottom-rebate-bottomleft-div">
                                <h6>STATUS : </h6>
                                <p1>{items["Status"]}</p1>
                            </div>
                            <div className="bottom-rebate-bottomleft-div">
                                <h6>STAGE : </h6>
                                <p1>{items["Stage"]}</p1>
                            </div>
                        </div> */}
                         <StageHeader rebateID={props.rebateID} />
                    </div>
                </div>

                <div>
                    <div className="rebate-head">
                        <h4>Rebate Details</h4>
                    </div>
                    <div className="underlinediv-24px">
                        <div className="th-underline"></div>
                    </div>
                    <div className="div-flex-column">
                        <div className="detail1">
                            <div className="detail1-left">
                                <h7>Program:</h7>
                            </div>
                            <div className="detail1-right">
                                <p2>{items["Program_Auto_Number"]}</p2>
                            </div>
                        </div>
                      
                        <div className="detail1">
                            <div className="detail1-left">
                                <h7>Created Account:</h7>
                            </div>
                            <div className="detail1-right">
                                <a href={"/Account?RebateId=" + props.rebateID}>{items["Account_Name"]}</a>
                            </div>
                        </div>

                        <div className="detail1">
                            <div className="detail1-left">
                                <h7>Created Contact: </h7>
                            </div>
                            <div className="detail1-right">
                                <a href={"/Contact?RebateId=" + props.rebateID}>{items["Contact_First_Name"] + " " + items["Contact_Last_Name"]}</a>
                            </div>
                        </div>

                        <div className="detail1">
                            <div className="detail1-left">
                                <h7>Created Location:</h7>
                            </div>
                            <div className="detail1-right">
                                <a href={"/Location?RebateId=" + props.rebateID}>{items["Location_Name"]}</a>
                            </div>
                        </div>

                        <div className="detail1">
                            <div className="detail1-left">
                                <h7>Project ID:</h7>
                            </div>
                            <div className="detail1-right">
                                <a href={"/Project?RebateId=" + props.rebateID}>{items["Created_Project"]}</a>
                            </div>
                        </div>

                        <div className="detail1">
                            <div className="detail1-left">
                                <h7>Utility Account #:</h7>
                            </div>
                            <div className="detail1-right">
                                <a href={"/UtilityAccount?RebateId=" + props.rebateID}>{items["Created_Utility_Account"]}</a>
                            </div>
                        </div>

                        <div className="detail1">
                            <div className="detail1-left">
                                <h7>Premise:</h7>
                            </div>
                            <div className="detail1-right">
                                <a href={"/Premise?RebateId=" + props.rebateID}>{items["Created_Premise"]}</a>
                            </div>
                        </div>

                    </div>
                    <div className="tables-containers">
                            <div className="lable-tables">
                                Notes:
                            </div>
                            {(notes.length > 0) ? (
                                    <div className="tables-containers">
                                        <FormatTable theadData={notesHeader} tbodyData={notes} />
                                    </div>) : (<div><p2>No Notes Found</p2></div>)}
                        </div>
                    <div className="tables-containers">
                        <div className="lable-tables">
                            Measure Calculation:
                        </div>
                        <FetchMesaureCalulations rebateID={props.rebateID} setMeasuresData={setMeasuresData} ></FetchMesaureCalulations>
                    </div>
                    <div className="tables-containers">
                        <div className="lable-tables">
                            Invoice Number:
                        </div>
                        <FetchInvoiceDetails rebateID={props.rebateID} setInvoiceData={setInvoiceData} />
                    </div>
                    <div className="tables-containers">
                        <div className="lable-tables">
                            Activity:
                        </div>
                        <FetchActivity rebateID={props.rebateID} setActivitysData={setActivitysData} />
                    </div>
                    <div className="tables-containers">
                        <div className="lable-tables">
                            Case:
                        </div>
                        <FetchCaseDetails rebateID={props.rebateID} setCaseData={setCaseData} />
                    </div>
                </div>
                {/* <Routes>
                    <Route path='/Account' element={<Account rebateID={items["rebateId"]}  />}></Route>
                </Routes> */}
            </div>
        )
    }

}

function FormatTable({ theadData, tbodyData }) {
    return (
        <table id="Notes_Calculations" className="Calculation-table">
            <thead>
                <tr>
                    {theadData.map((heading, i) => {
                        return <th key={'ms-h-' + i}>{heading.replaceAll("_", " ")}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {tbodyData.map((row, index) => {
                    return <tr key={index}>
                        {theadData.map((key, index) => {
                            return (<td key={'ms-r-' + index}>{row[key]}</td>)
                        })}
                    </tr>;
                })}
            </tbody>
        </table>
    )
}
export default FetchRebate
const Print = () => {
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(false);
}