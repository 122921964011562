import React, { useState } from 'react';
import { SignOutButton } from './SignOut';
import '../../src/assets/css/style.css';
import { useMsal, useAccount } from "@azure/msal-react";

export const TopDisplayBar = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
console.log(useMsal());
    const handleMouseEnter = () => {
        setIsDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        setIsDropdownOpen(false);
    };

    return (
        <header className="navbar navbar-expand-lg">
            <div className="container maxwidth">
                <a className="navbar-brand" href="/Home">
                    <img src={require('././../assets/images/whitelogo.svg').default} alt="Logo" width="132" height="17" />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li
                            className={`nav-item dropdown ${isDropdownOpen ? 'show' : ''}`}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <a
                                className={`nav-link dropdown-toggle ${isDropdownOpen ? 'show' : ''}`}
                                href="#"
                                id="navbarDropdown"
                                role="button"
                                aria-expanded={isDropdownOpen ? 'true' : 'false'}
                            >
                                Welcome
                                <span style={{ fontWeight: 900 }}>, </span>
                                {account.name}
                                <img
                                    src={require('././../assets/images/whitearrowdown.svg').default}
                                    className="dropdown-image"
                                    alt="Dropdown Arrow"
                                />
                            </a>
                            <ul
                                className={`dropdown-menu dropdown-menu-end ${isDropdownOpen ? 'show' : ''}`}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Administration
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <SignOutButton />
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <script src="https://code.jquery.com/jquery-2.2.4.min.js" integrity="sha256-BbhdlvQf/xTY9gja0Dq3HiwQF8LaCRTXxZKRutelT44=" crossOrigin="anonymous"></script>
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>
            </div>
        </header>
    );
};

export default TopDisplayBar;
