import TopDisplayBar from "../../components/TopDisplayBar"
import Banner from "../../components/Banner"
import TopNavigation from "../../components/TopNavigation"
import LeftNavigation from "../../components/LeftNavigation"
import BreadCrumb from "../../components/BreadCrumb"
import RebateFilter from "./RebateFilter"
import React, { useEffect, useState } from "react";
import Rebate from "../Rebate"
import Account from "../Account"
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import CatalystLoginPage from '../CatalystLoginPage'
// import { useNavigate } from "react-router-dom";
import { Outlet, Route, Routes, Link } from 'react-router-dom';
export const Home = () => {
    return (
        <div>
            <AuthenticatedTemplate>
                <div className="tab-pane fade show active" id="content1" role="tabpanel" aria-labelledby="tab1">
                    <div className="tabshead">
                        <h4>Home</h4>
                    </div>
                    <div className="underlinediv">
                        <div className="th-underline"></div>
                    </div>
                    <RebateFilter />
                </div>
                <Routes>
                    <Route path='/Rebate' element={<Rebate />}></Route>
                </Routes>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <CatalystLoginPage />
            </UnauthenticatedTemplate>
        </div >

    );
}
export default Home;