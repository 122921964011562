import LeftNavigation from "../../components/LeftNavigation"
import BreadCrumb from "../../components/BreadCrumb"
import './../../assets/css/style.css'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import CatalystLoginPage from '../CatalystLoginPage'
import FetchActivity from "./fetchActivity"
import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../utils/constant"
import { utils, writeFile } from 'xlsx';
import * as Common from "../../utils/common";

export const ActivityCalculations = () => {
    const queryParameters = new URLSearchParams(window.location.search)
    const rebateId = decodeURI(queryParameters.get("RebateId"));
    const [activitysData, setActivitysData] = useState([]);
    const [items, setItems] = useState([]);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        async function getRebateDetails() {
            try {
                setLoading(true);
                await axios.get(Constants._REBATE_HEADER_API, {
                    params: {
                        rebateId: rebateId,
                    }
                })
                    .then((response) => {
                        const result = []
                        var data = response.data;
                        setItems(data);
                        if (data.length > 0) {
                            setItems(data[0]);
                            console.log(data[0]);
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            } catch (error) {
                console.log("error", error);
            }
        }

        // if (props.rebateID !== "")
        getRebateDetails();
    }, []);
    //Export to excel
    const handleExportToExcel = () => {
        try {
            if (activitysData != null) {
                const wb = utils.book_new();
                const ws = utils.json_to_sheet([]);
                let activity = Common.GetJsonArray(activitysData);
                utils.sheet_add_json(ws, activity, { origin: 'A1', skipHeader: true });
                utils.book_append_sheet(wb, ws, 'Activities');
                writeFile(wb, rebateId + '_Activities.xlsx');
            }

        }
        catch (error) {
            console.log("error", error);
        }
    }
    return (
        <div>
            <AuthenticatedTemplate>
                <div className="container-content">
                    <BreadCrumb RebateID={rebateId} />
                    <div className="sidenav-bar-div">
                        <LeftNavigation />
                        <div className="right-content">
                            <div id='printablediv'>
                                <div>
                                    <div className="CAT-Rebate-Banner">
                                        <div className="top-rebate-banner-div">
                                            <div className="top-left-banner-div">
                                                <h2 className="rebate-h2-banner">{rebateId}</h2>
                                            </div>
                                            <div className="top-right-banner-div">
                                                <button className="download-button" onClick={() => { handleExportToExcel(); }}>
                                                    <img src={require('../../assets/images/downloadicon.svg').default} alt="Button Image" className="download-icon" />
                                                </button>
                                                <button className="download-button" onClick={Print}>
                                                    <img src={require('../../assets/images/pdf.png')} alt="Button Image" className="download-icon" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="bottom-rebate-banner-div">
                                            <div className="bottom-rebate-bottomleft-div">
                                                <h6>STATUS:</h6><p1>{items["Status"]}</p1>
                                            </div>
                                            <div className="bottom-rebate-bottomleft-div">
                                                <h6>STAGE:</h6><p1>{items["Stage"]}</p1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <FetchActivity rebateID={rebateId} setActivitysData={setActivitysData} />
                            </div>
                        </div>
                    </div>
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <CatalystLoginPage />
            </UnauthenticatedTemplate>
        </div>
    );
}
export default ActivityCalculations;
const Print = () => {
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(false);
}