import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../utils/constant";

const FetchInvoiceDetails = (props) => {
  //  console.log(props);
  const [items, setItems] = useState([]);
  const [header, setHeader] = useState([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
      //console.log('i triggered once');
      getInvoice();
  }, []);

  async function getInvoice() {
      try {
          // console.log("props Service Call");
          setLoading(true);
          await axios.get(Constants._INVOICE_BY_REBATE_ID_API, {
              params: {
                  rebateId: props.rebateID,
              }
          })
              .then((response) => {
                  const result = []
                  var data = response.data;
                  if (data.length > 0) {
                      setItems(data);
                      setHeader(Object.keys(data[0]));
                      props.setInvoiceData(data);
                      //console.log('I got data');
                  }
              })
              .catch((error) => {
                  console.log(error);
              }).finally(
                  () => setLoading(false)
              );

      } catch (error) {
          console.log("error", error);
      }
  }


  // props.setMeasuresData(items);
  return (
      <>
          {isLoading && <div className="containerloader"><div id="loader" class="loader"></div></div>}
          {!isLoading && (
              (items.length > 0)? (
                  <div className="tables-containers">
                      <FormatTable theadData={header} tbodyData={items} rID={props.rebateID} />
                  </div>) : (<div><p2>No Invoice Found</p2></div>)

          )}
      </>
  )
}
function FormatTable({ theadData, tbodyData, rID }) {
    return (
        <table id="Invoice_Calculations" className="Calculation-table">
            <thead>
                <tr>
                    {theadData.map((heading, i) => {
                        return <th key={'ms-h-' + i}>{heading.replaceAll("_", " ")}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {tbodyData.map((row, index) => {
                    return <tr key={index}>
                        {theadData.map((key, index) => {
                            return (key.toLowerCase() == "invoice_id") ? (<td key={'ms-r-' + index}>
                                <a href={'/InvoiceDetails?InvoiceId='
                                    + encodeURI(row['Invoice_ID'])
                                    + "&RebateId=" + rID} >{row[key]}</a>
                            </td>)
                                : (<td key={'ms-r-' + index}>{row[key]}</td>)
                        })}
                    </tr>;
                })}
            </tbody>
        </table>
    )
}
export default FetchInvoiceDetails