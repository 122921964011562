import UserDataTable from "./userDataTable";
import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../utils/constant";
import { utils, writeFile } from 'xlsx';
import * as Common from "../../utils/common";
import moment from "moment/moment";
const AllUsers = (props) => {
    const [items, setItems] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        async function getUsersResult() {
            try {
                setLoading(true)
                await axios.get(Constants._GET_ALL_USERS)
                    .then((response) => {
                        const result = []
                        var data = response.data;
                        for (var i = 0; i < data.length; i++) {
                            var val = data[i];
                            let adminText = 'No';
                            let enableText = 'No';
                            if (val["IsEnabled"])
                                enableText = 'Yes'
                            if (val["IsAdmin"])
                                adminText = 'Yes'
                           // console.log(val);
                            result.push([val["FirstName"], val["LastName"], val["Email"], val["BusinessUnit"], enableText, adminText, val["Id"]]);
                        }
                        setItems(result);
                        props.usersCount(result.length);
                    })
                    .catch((error) => {
                        console.log(error);
                        alert(error);

                    }).finally(
                        () => setLoading(false)
                    );

            } catch (error) {
                console.log("error", error);
                alert(error);
            }
        }
        getUsersResult();
    }, [props]);
    const handleExportToExcel = () => {

        try {
            if (items !== null) {
                //console.log(items);
                let fileName =  moment(new Date()).format("MM_DD_yyyy") + '_User_Information.xlsx'
                let Heading = [['FirstName', 'Last Name', 'Email', 'Business Unit', 'Enabled', 'Admin','Id']];
                //Had to create a new workbook and then add the header
                const wb = utils.book_new();
                const ws = utils.json_to_sheet([]);
                utils.sheet_add_aoa(ws, Heading);
                //Starting in the second row to avoid overriding and skipping headers
                utils.sheet_add_json(ws, items, { origin: 'A2', skipHeader: true });
                utils.book_append_sheet(wb, ws, 'User Information');
                writeFile(wb, fileName);
            }
        }
        catch (error) {
            console.log("error", error);
        }
    }
    return (
        <>
            <div className="user-grid">
                <div className="audit-btn-div">
                    <button className="btn-dwnld" onClick={() => { handleExportToExcel(); }}>
                        <img src={require('../../assets/images/downloadicon.svg').default} alt="Button Image" className="btn-dwnld.img" />
                    </button></div></div>
            {isLoading && <ul className="ul-table"><div className="containerloader"><div id="loader" class="loader"></div></div></ul>}
            {!isLoading && (
                <ul className="ul-table">
                    <UserDataTable props={items} />
                </ul>
            )}
        </>
    )
}

export default AllUsers

