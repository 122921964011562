import React from 'react'

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import CatalystLoginPage from '../CatalystLoginPage'
export const UserRole = () => {
    return (
        <div>
            <AuthenticatedTemplate>
                <div className="tab-pane fade active show" id="content3" role="tabpanel" aria-labelledby="tab3">
                    <div className="tabshead">
                       
                        <h4>User Role</h4>
                    </div>

                    <div className="underlinediv">
                        <div className="th-underline"></div>
                    </div>

                    <div className="users-no">
                        <span style={{color:' #D63827'}}>6</span> Users
                    </div>

                    <div className="search-filter-div">
                        <div className="filter-row">

                            <div className="search-container">
                                <input type="text" id="searchuser-role" name="Search" className="searchbar" placeholder="Search by name or partial email..." />
                                <img className="search-icon" src={require('../../assets/images/glasssearch.svg').default} />

                            </div>

                            <div className="popup-container">
                                <button id="action-filter-btn-user" className="action-filterbutton">
                                    <img src={require('../../assets/images/filter.svg').default} alt="Button Image" className="filtericon" />Filter
                                </button>
                            </div>

                            <div id="pop-content-user" className="popup-content">
                                <div className="pop-up-form">
                                    <div className="filter-column">
                                        <div className="div-input-updown">
                                            <label>
                                                <h8>ROLE</h8>
                                            </label>
                                            <div className="select-container-pop">
                                                <select id="roles" className="select-options">
                                                    <option value="role1">All Roles</option>
                                                    <option value="role2">Roles1</option>
                                                    <option value="role3">Roles2</option>
                                                    <option value="role4">Roles3</option>
                                                </select>
                                                <div className="arrow-container">
                                                    <button id="arrowUp"><img src={require('../../assets/images/up-arrow.svg').default} alt="Up" /></button>
                                                    <button id="arrowDown"><img src={require('../../assets/images/downarrow.svg').default} alt="Down" /></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="div-input-updown">
                                            <label>
                                                <h8>Business Unit</h8>
                                            </label>
                                            <div className="select-container-pop">
                                                <select id="roles" className="select-options">
                                                    <option value="all">Business Unit</option>
                                                    <option value="unit1">Unit1</option>
                                                    <option value="unit2">Unit2</option>
                                                    <option value="unit3">Unit3</option>
                                                </select>
                                                <div className="arrow-container">
                                                    <button id="arrowUp"><img src={require('../../assets/images/up-arrow.svg').default} alt="Up" /></button>
                                                    <button id="arrowDown"><img src={require('../../assets/images/downarrow.svg').default} alt="Down" /></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="div-input-updown">
                                            <label>
                                                <h8>Region</h8>
                                            </label>
                                            <div className="select-container-pop">
                                                <select id="roles" className="select-options">
                                                    <option value="region">Region</option>
                                                    <option value="region1">Region1</option>
                                                    <option value="region2">Region2</option>
                                                    <option value="region3">Region3</option>
                                                </select>
                                                <div className="arrow-container">
                                                    <button id="arrowUp"><img src={require('../../assets/images/up-arrow.svg').default} alt="Up" /></button>
                                                    <button id="arrowDown"><img src={require('../../assets/images/downarrow.svg').default} alt="Down" /></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="div-input-updown">
                                            <label>
                                                <h8>ADMIN</h8>
                                            </label>
                                            <div className="select-container-pop">
                                                <select id="roles" className="select-options">
                                                    <option value="admin">ADMIN</option>
                                                    <option value="admin1">ADMIN1</option>
                                                    <option value="admin2">ADMIN2</option>
                                                    <option value="admin3">ADMIN3</option>
                                                </select>
                                                <div className="arrow-container">
                                                    <button id="arrowUp"><img src={require('../../assets/images/up-arrow.svg').default} alt="Up" /></button>
                                                    <button id="arrowDown"><img src={require('../../assets/images/downarrow.svg').default} alt="Down" /></button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="filter-row">
                                <button id="add-employee" className="add-employee-btn">
                                    <img src={require('../../assets/images/add.svg').default} alt="Button Image" className="filtericon" />Add Employee
                                </button>
                                <button className="btn-dwnld">
                                    <img src={require('../../assets/images/downloadicon.svg').default} alt="Button Image" className=".btn-dwnld.img" />
                                </button>



                            </div>
                        </div>

                    </div>

                    <table id="table-user-role" className="tabledata" cellspacing="0" width="100%">
                        <thead>
                            <tr>
                                <th className="no-sort">Role</th>
                                <th className="no-sort">Business Unit</th>
                                <th className="no-sort">Region</th>
                                <th className="no-sort">Admin</th>
                                <th className="no-sort">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Lorem Ipsum</td>
                                <td>0123456-ABC</td>
                                <td>Doler Set</td>
                                <td><img src={require('../../assets/images/greentick.svg').default} alt="tick" className="greentick" /></td>
                                <td>
                                    <button className="action-editbutton" onclick="window.location.href='example.html'">
                                        <img src={require('../../assets/images/PencilSquare.svg').default} alt="Button Image" className="editicon" />Edit
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>Lorem Ipsum</td>
                                <td>0123456-ABC</td>
                                <td>Doler Set</td>
                                <td>No</td>
                                <td>
                                    <button className="action-editbutton" onclick="window.location.href='example.html'">
                                        <img src={require('../../assets/images/PencilSquare.svg').default} alt="Button Image" className="editicon" />Edit
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>Lorem Ipsum</td>
                                <td>0123456-ABC</td>
                                <td>Doler Set</td>
                                <td>No</td>
                                <td>
                                    <button className="action-editbutton" onclick="window.location.href='example.html'">
                                        <img src={require('../../assets/images/PencilSquare.svg').default} alt="Button Image" className="editicon" />Edit
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>Lorem Ipsum</td>
                                <td>0123456-ABC</td>
                                <td>Doler Set</td>
                                <td>No</td>
                                <td>
                                    <button className="action-editbutton" onclick="window.location.href='example.html'">
                                        <img src={require('../../assets/images/PencilSquare.svg').default} alt="Button Image" className="editicon" />Edit
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>Lorem Ipsum</td>
                                <td>0123456-ABC</td>
                                <td>Doler Set</td>
                                <td><img src={require('../../assets/images/greentick.svg').default} alt="tick" className="greentick" /></td>
                                <td>
                                    <button className="action-editbutton" onclick="window.location.href='example.html'">
                                        <img src={require('../../assets/images/PencilSquare.svg').default} alt="Button Image" className="editicon" />Edit
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>Lorem Ipsum</td>
                                <td>0123456-ABC</td>
                                <td>Doler Set</td>
                                <td><img src={require('../../assets/images/greentick.svg').default} alt="tick" className="greentick"/></td>
                                <td>
                                    <button className="action-editbutton" onclick="window.location.href='example.html'">
                                        <img src={require('../../assets/images/PencilSquare.svg').default} alt="Button Image" className="editicon" />Edit
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>Lorem Ipsum</td>
                                <td>0123456-ABC</td>
                                <td>Doler Set</td>
                                <td><img src={require('../../assets/images/greentick.svg').default} alt="tick" className="greentick" /></td>
                                <td>
                                    <button className="action-editbutton" onclick="window.location.href='example.html'">
                                        <img src={require('../../assets/images/PencilSquare.svg').default} alt="Button Image" className="editicon" />Edit
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>Lorem Ipsum</td>
                                <td>0123456-ABC</td>
                                <td>Doler Set</td>
                                <td><img src={require('../../assets/images/greentick.svg').default} alt="tick" className="greentick" /></td>
                                <td>
                                    <button className="action-editbutton" onclick="window.location.href='example.html'">
                                        <img src={require('../../assets/images/PencilSquare.svg').default} alt="Button Image" className="editicon" />Edit
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>Lorem Ipsum</td>
                                <td>0123456-ABC</td>
                                <td>Doler Set</td>
                                <td><img src={require('../../assets/images/greentick.svg').default} alt="tick" className="greentick" /></td>
                                <td>
                                    <button className="action-editbutton" onclick="window.location.href='example.html'">
                                        <img src={require('../../assets/images/PencilSquare.svg').default} alt="Button Image" className="editicon" />Edit
                                    </button>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <CatalystLoginPage />
            </UnauthenticatedTemplate>
        </div >
    )
}
export default UserRole