import { BrowserRouter, Route, Routes, Link,useLocation } from 'react-router-dom';
import Account from '../pages/Account'
import Contact from '../pages/Contact'
import Rebate from '../pages/Rebate'
import { SignInButton } from "../components/signInButton";



export const LeftNavigation = () => {
    const location = useLocation();
    const isLinkActive = (path) => {
        return location.pathname === path;
    };
    const queryParameters = new URLSearchParams(window.location.search)
    const rebateId = decodeURI(queryParameters.get("RebateId"));

    return (

        <>
            <div className="left-content">
                <ul className="nav flex-column">
                <li className="nav-item">
                    <Link
                        className={`nav-link-sb ${isLinkActive('/Rebate') ? 'active' : ''}`}
                        to={{
                            pathname: '/Rebate',
                            search: '?RebateId=' + encodeURI(rebateId)
                        }}
                    >
                        Rebate
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link-sb ${isLinkActive('/Account') ? 'active' : ''}`}
                        to={{
                            pathname: '/Account',
                            search: '?RebateId=' + encodeURI(rebateId)
                        }}
                    >
                        Account
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link-sb ${isLinkActive('/Contact') ? 'active' : ''}`}
                        to={{
                            pathname: '/Contact',
                            search: '?RebateId=' + encodeURI(rebateId)
                        }}
                    >
                        Contact
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link-sb ${isLinkActive('/Location') ? 'active' : ''}`}
                        to={{
                            pathname: '/Location',
                            search: '?RebateId=' + encodeURI(rebateId)
                        }}
                    >
                        Location
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link-sb ${isLinkActive('/Project') ? 'active' : ''}`}
                        to={{
                            pathname: '/Project',
                            search: '?RebateId=' + encodeURI(rebateId)
                        }}
                    >
                        Project
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link-sb ${isLinkActive('/UtilityAccount') ? 'active' : ''}`}
                        to={{
                            pathname: '/UtilityAccount',
                            search: '?RebateId=' + encodeURI(rebateId)
                        }}
                    >
                        Utility Account
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link-sb ${isLinkActive('/Premise') ? 'active' : ''}`}
                        to={{
                            pathname: '/Premise',
                            search: '?RebateId=' + encodeURI(rebateId)
                        }}
                    >
                        Premise
                    </Link>
                </li>
                {/* <li className="nav-item">
                    <Link
                        className={`nav-link-sb ${isLinkActive('/Contractor') ? 'active' : ''}`}
                        to={{
                            pathname: '/Contractor',
                            search: '?RebateId=' + encodeURI(rebateId)
                        }}
                    >
                        Contractor
                    </Link>
                </li> */}
                <li className="nav-item">
                    <Link
                        className={`nav-link-sb ${isLinkActive('/MeasureCalculations') ? 'active' : ''}`}
                        to={{
                            pathname: '/MeasureCalculations',
                            search: '?RebateId=' + encodeURI(rebateId)
                        }}
                    >
                        Measure Calculations
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link-sb ${isLinkActive('/Invoice') ? 'active' : ''}`}
                        to={{
                            pathname: '/Invoice',
                            search: '?RebateId=' + encodeURI(rebateId)
                        }}
                    >
                        Invoice
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link-sb ${isLinkActive('/Activity') ? 'active' : ''}`}
                        to={{
                            pathname: '/Activity',
                            search: '?RebateId=' + encodeURI(rebateId)
                        }}
                    >
                        Activity
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link-sb ${isLinkActive('/Case') ? 'active' : ''}`}
                        to={{
                            pathname: '/Case',
                            search: '?RebateId=' + encodeURI(rebateId)
                        }}
                    >
                        Case
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={`nav-link-sb ${isLinkActive('/Documents') ? 'active' : ''}`}
                        to={{
                            pathname: '/Documents',
                            search: '?RebateId=' + encodeURI(rebateId)
                        }}
                    >
                        Documents
                    </Link>
                </li>
                </ul>

            </div>

            {/* <Routes>
                <Route path='/Rebate' element={<Rebate  />}></Route>
                <Route path='/Account' element={<Account rebateID={rebateId} />}></Route>
                <Route path='/Contact' element={<Contact />}></Route>
            </Routes> */}

            {/* <SignInButton />
                <h4 className="title is-4">
                    Please sign in to view employee info.
                </h4> */}

        </>

    )
}
export default LeftNavigation;