import React, { useEffect, useState } from "react";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import CatalystLoginPage from '../CatalystLoginPage'
import AddUser from "./addUser";
import AllUsers from "./userInformation";
import axios from "axios";
import * as Constants from "./../../utils/constant";
import { useNavigate } from "react-router-dom";
export const UserManagement = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [itemsCount, setItemsCount] = useState(0);
    useEffect(() => {
        async function isAdmininstrator() {
            try {
                await axios.get(Constants._IS_CATALYSTADMIN)
                    .then((response) => {
                        var data = response.data;
                        console.log(data);
                        if (data === false)
                            RedirectToAcessDeniedPage();
                       
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            } catch (error) {
                console.log("error", error);
            }
        }
        isAdmininstrator();
    }, []);
    const navigate = useNavigate();
    const RedirectToAcessDeniedPage = () => {

       navigate("/AccessDenied");
    }

    function handleCloseModal(event, data) {
       // console.log(event, data);
        setIsOpen(false);
    }



    const usersCount = (count) => {
        setItemsCount(count);
    };


    return (
        <div>
            <AuthenticatedTemplate>
                <div className="tab-pane fade active show" id="content4" role="tabpanel" aria-labelledby="tab4">
                    <div className="tabshead">
                        <h4>User Management</h4>
                    </div>
                    <div className="underlinediv">
                        <div className="th-underline"></div>
                    </div>
                    <div className="users-no">
                        <span style={{ color: '#D63827' }}>{itemsCount}</span> Users
                    </div>
                    <div className="search-filter-div">
                        <div className="filter-row">
                            <div className="search-container">
                                {/* <input type='email' id="searchuser-role" name="Search" className="searchbar"
                                    placeholder="Search by name or partial email..." />
                                <img className="search-icon" src={require('../../assets/images/glasssearch.svg').default} /> */}
                            </div>

                            <div className="popup-container">
                                {/* <button id="action-filter-btn-user2" className="action-filterbutton" >
                                    <img src={require('../../assets/images/filter.svg').default} alt="Button Image"
                                        className="filtericon" />Filter
                                </button> */}
                            </div>
                            {isOpen &&
                                <AddUser IsModalOpened={isOpen} isNewMode={true}
                                    onCloseModal={handleCloseModal} itemId={0}
                                />

                            }

                        </div>
                        <div>
                            <div className="filter-row">
                                <button id="add-employee-management" className="add-employee-btn" onClick={() => setIsOpen(true)}>
                                    <img src={require('../..//assets/images/add.svg').default} alt="Button Image" className="filtericon" />Add Employee
                                </button>


                                {/* <div className="overlay" onclick="closePopupWindow()"></div>
                                <div className="popup-download">
                                    <button className="popup-dwnd-btn" onclick="">Excel</button>
                                </div> */}
                            </div>
                        </div>

                    </div>
                    <AllUsers usersCount={usersCount} />

                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <CatalystLoginPage />
            </UnauthenticatedTemplate>
        </div >
    )
}

export default UserManagement
