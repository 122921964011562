import LeftNavigation from "../../components/LeftNavigation"
import BreadCrumb from "../../components/BreadCrumb"
import './../../assets/css/style.css'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import CatalystLoginPage from '../CatalystLoginPage'
import FetchInvoiceDetails from "./fetchInvoiceDetails";


export const InvoiceDetails = () => {
    const queryParameters = new URLSearchParams(window.location.search)
    const rebateId = decodeURI(queryParameters.get("RebateId"));
    const invoiceId = decodeURI(queryParameters.get("InvoiceId"));
    return (
        <div>
            <AuthenticatedTemplate>
                <div className="container-content">
                    <BreadCrumb RebateID={rebateId} />
                    <div className="sidenav-bar-div">
                        <LeftNavigation />
                        <div className="right-content">
                            <FetchInvoiceDetails rebateID={rebateId} invoiceID={invoiceId} />
                        </div>
                    </div>
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <CatalystLoginPage />
            </UnauthenticatedTemplate>
        </div>
    );
}
export default InvoiceDetails;