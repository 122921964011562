import SearchDataTable from "./searchdatatable";
import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../utils/constant";
const SearchResult = (props) => {
    const [items, setItems] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
       
        async function getSearchResult() {
            try {
                setLoading(true)
                await axios.get(Constants._SEARCH_API, {
                    params: {
                        searchParamater: props.searchString,
                        filterType: props.filterInfo
                    }
                })
                    .then((response) => {
                        const result = []
                        var data = response.data;
                        for (var i = 0; i < data.length; i++) {
                            var val = data[i];
                            var def = Object.keys(val);
                            console.log(val);
                            let redirectURL = Constants._REBATE_PAGE_URL + "?RebateId=" + val["Rebate_ID"]
                            result.push([val["Program_Auto_Number"], val["Rebate_ID"], val["Contact"], val["Account_Name"], val["Location_Name"], val["Project_Name"], redirectURL]);
                        }
                        console.log(result);
                        setItems(result);
                        // setItems(data);
                        //setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        alert(error);

                    }).finally(
                        () => setLoading(false)
                    );;;

            } catch (error) {
                console.log("error", error);
                alert(error);
            }
        }
        if (props.ready)
            getSearchResult();
    }, [props]);
    return (
        <>
            {isLoading && <ul className="ul-table"><div className="containerloader"><div id="loader" class="loader"></div></div></ul>}
            {!isLoading && (
                <ul className="ul-table">
                    <SearchDataTable props={items} />
                </ul>
            )}
        </>
    )
}
export default SearchResult

