import React, { useEffect, useRef, useState } from "react"
import AddUser from "./addUser";
import ReactDOM from 'react-dom';
import $ from 'jquery'
const UserDataTable = ({ props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [editId, setEditId] = useState();
    $.DataTable = require('datatables.net');

    function openFromParent() {
        setIsOpen(true);
    }

    function handleCloseModal(event, data) {
      //  console.log(event, data);
        setIsOpen(false);
        window.location.reload(false);
    }

    function handleAfterOpen(event, data) {
       // console.log(event, data);
    }
    const tableRef = useRef();
    useEffect(() => {
        //'<"top"ip>rt<"bottom"ip>',
        const table = $(tableRef.current).DataTable(
            {
                data: props,
                dom: 'Bfrtip',
                search: true,
                columns: [
                    { title: 'First Name' },
                    { title: 'Last Name' },
                    { title: 'Email' },
                    { title: 'Business Unit' },
                    { title: 'Enabled' },
                    { title: 'Admin' }
                ],
                columnDefs: [
                    {
                        'targets': [6],
                        createdCell: (td, cellData, rowData, row, col) => {
                            ReactDOM.render(<button onClick={() => { setIsOpen(true); setEditId(rowData[6]); }} className="action-button">Edit</button>, td);
                        }
                    }
                ],
                destroy: true,  // I think some clean up is happening here

            }
        )
        // Extra step to do extra clean-up.
        return function () {
            //  console.log("Table destroyed")
            table.destroy()
        }
    }, [props])

    return (
        <>
          
            <div>
                <table className="tabledata" width="100%" ref={tableRef}></table>
            </div>
            {isOpen &&
                <AddUser IsModalOpened={isOpen} itemId={editId} isNewMode={false}
                    onCloseModal={handleCloseModal}
                    onAfterOpen={handleAfterOpen} />

            }
        </>

    )


}


export default UserDataTable