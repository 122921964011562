import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../utils/constant";
import StageHeader from '../../components/StageHeader';
import { utils, writeFile } from 'xlsx';
import * as fields from "../../headerFields";
import * as Common from "../../utils/common";
const FetchExtentedMeasure = (props) => {
    const [items, setItems] = useState([]);
    const [html, setHTML] = useState('');
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        async function getExtentedMeasureDetails() {
            setLoading(true);
            try {
                await axios.get(Constants._EXTENDED_DETAILS_BY_MEASURE_ID_API, {
                    params: {
                        measureInternalId: props.measureID,
                    }
                }).then((response) => {
                    var data = response.data;
                    if (data != null) {
                        setItems(data);
                        var generatedHTML = Common.FormatData(data, []);
                        setHTML(generatedHTML);
                        //setLoading(false);
                    }
                })
                    .catch((error) => {
                        console.log(error);
                        alert(error);
                    }).finally(
                        () => setLoading(false)
                    );;;

            } catch (error) {
                console.log("error", error);
                alert(error);
            }
        }
        // if (props.rebateID !== "")
        getExtentedMeasureDetails();
    }, []);
    //Export to excel
    const handleExportToExcel = () => {
        try {
            if (items !== null) {
                const wb = utils.book_new();
                const ws = utils.json_to_sheet([]);
                let exlItems = Common.GetJsonArrayForOneItem(items);
                utils.sheet_add_json(ws, exlItems, { origin: 'A1', skipHeader: true });
                utils.book_append_sheet(wb, ws, 'ExtentedMeasure');
                writeFile(wb, props.rebateID + '_ExtentedMeasure.xlsx');
            }
        }
        catch (error) {
            console.log("error", error);
        }
    }

    return (
        <>
            {isLoading && <div className="containerloader"><div id="loader" class="loader"></div></div>}
            {!isLoading && (   <div id='printablediv'>
                <div>
                    <div className="CAT-Rebate-Banner">
                        <div className="top-rebate-banner-div">
                            <div className="top-left-banner-div">
                                <h2 className="rebate-h2-banner">{props.rebateID}</h2>
                            </div>
                            <div className="top-right-banner-div">
                                <button className="download-button" onClick={() => { handleExportToExcel(); }}>
                                    <img src={require('../../assets/images/downloadicon.svg').default} alt="Button Image" className="download-icon" />
                                </button>
                                <button className="download-button" onClick={Print}>
                                        <img src={require('../../assets/images/pdf.png')} alt="Button Image" className="download-icon" />
                                    </button>
                            </div>
                        </div>
                        <StageHeader rebateID={props.rebateID} />
                    </div>
                </div>
                <div>
                    <div className="rebate-head">
                        <h4>Extented Measure Details</h4>
                    </div>
                    <div>
                        <div className="underlinediv-24px">
                            <div className="th-underline"></div>
                        </div>

                        <div dangerouslySetInnerHTML={{ __html: html }} />
                    </div>

                </div>
            </div>)}
        </>
    )

}

export default FetchExtentedMeasure
const Print = () => {
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(false);
}