export const accountFields =["Account_Name","Account_Manager","Account_Number","Account_Type","Accounting_Code","Address_1","Address_1__Address_Type","Address_1__City","Address_1__ID","Address_1__Latitude","Address_1__Name","Address_1__Post_Office_Box","Address_1__Primary_Contact_Name","Address_1__State_Province","Address_1__Street_1","Address_1__Street_2","Address_1__Street_3","Address_1__ZIP_Postal_Code","Address_2__Address_Type","Address_2__City","Address_2__Country_Region","Address_2__County","Address_2__ID","Address_2__Name","Address_2__Post_Office_Box","Address_2__Primary_Contact_Name","Address_2__State_Province","Address_2__Street_1","Address_2__Street_2","Address_2__Street_3","Address_2__Telephone_1","Address_2__ZIP_Postal_Code","Address_Phone","Billing_Account","BPI_Certification","Business_Type","Category","Contractor__","Created_By","Created_By_Username","Created_On","E_mail","External_System","External_System_ID","Legacy_ID","License_Expiration_Date","License_Number","License_Status","Owner","Primary_Contact","Status","Supplier_Connection_ID__Workday_","Supplier_ID__Workday_","Tax_Exempt","Tax_Exempt_Number","Tax_ID_Number"];
export const contactFields =["Account","Address_1__ID","Address_1__Address_Type","Address_1__City","Address_1","Address_1__Country_Region","Address_1__County","Address_1__Street_1","Address_1__Street_2","Address_1__Street_3","Address_1__Name","Address_1__ZIP_Postal_Code","Address_1__Post_Office_Box","Address_1__Primary_Contact_Name","Address_1__State_Province","Address_1__Phone","Address_2__Address_Type","Address_2__City","Contact","External_System","External_System_ID","Created_By","Created_On","Full_Name","Accounting_Code","Owner","Parent_Contact","Status","Home_Phone"];
export const locationFields =["External_System","External_System_ID","Heating_Fuel","Home_Style","Home_Type","Location_ID","Water_Heater_Type","Location_Contacts","Location_Accounts","Created_By","Created_On","Above_Grade_SQFT","Basement","Basement_SQFT","Building_Name","Building_Type","City","Climate","County","Location","MEMD_Building_Type","Name","Number_of_Units","Operating_Hours","State","Street_1","Street_2","System","Total_SQFT","Weather_Zone","Year_Built","Zip_Code","Owner","Status"];
export const projectFields =["Project","Account__Location_","Application_Type","Assessment","Assessment_Completion_Date","Subprogram","Created_By_Field_Tool","Customer_Signature_Date","External_System","External_System_ID","Gas_Utility_Account","Project_Name","Qualified_Lifetime_Gallons_Savings","Qualified_Lifetime_Gigajoule_Savings","Qualified_Lifetime_kWh_Savings","Qualified_Lifetime_MCF_Savings","Qualified_Lifetime_Therm_Savings","Qualified_Gallons_Savings","Location","Project_ID","Premise","Rebate","Application_Received_Date","Rebate_Payable_To","Retrofit_New_Construction","Qualified_kWh_Savings","Qualified_kW_Savings","Qualified_Therms_Savings","Qualified_Therm_Demand_Savings","Total_Incentive_Amount","Utility_Account","Year_Built","Owner","Status"];
export const utilityAccountFields =["External_System","External_System_ID","Rate_Code","Utility_Account_ID","Created_By","Created_On","Utility_Client","Electric_Utility_Account_Number","Gas_Utility_Account_Number","Utility_Account","Utility_Account_Type","Owner","Status"];
export const premiseFields =["Premise_Number","Premise_Type","Name","Premise","Owner","Status"];
export const projectMesasureFields =["Contractor", "Contractor_ID","Measure_Configuration_Name","Created_By","Created_On","Gallons_Savings","kW_Savings","Incentive_Total","kWh_Savings","Name","Measure_Configuration","Project","MCF_Savings","Therm_Savings","Owner","Efficient_Measure","Base_Measure","Measure_ID","Quantity_Installed"];
export const invoiceFields =["Created_By","Created_On","Measure_Calculation","Project","Invoice_ID","Owner","Description","Quantity"];
export const issueFields =["Issue_ID"];
export const activitiesFields =["Subject"];
export const emptyFields =[];
export const caseFields =["Case_Number"];
export const contractorFields =["Account_Name","Account_Manager","Account_Number","Account_Type","Accounting_Code","Address_1","Address_1__Address_Type","Address_1__City","Address_1__ID","Address_1__Latitude","Address_1__Name","Address_1__Post_Office_Box","Address_1__Primary_Contact_Name","Address_1__State_Province","Address_1__Street_1","Address_1__Street_2","Address_1__Street_3","Address_1__ZIP_Postal_Code","Address_2__Address_Type","Address_2__City","Address_2__Country_Region","Address_2__County","Address_2__ID","Address_2__Name","Address_2__Post_Office_Box","Address_2__Primary_Contact_Name","Address_2__State_Province","Address_2__Street_1","Address_2__Street_2","Address_2__Street_3","Address_2__Telephone_1","Address_2__ZIP_Postal_Code","Address_Phone","Billing_Account","BPI_Certification","Business_Type","Category","Contractor__","Created_By","Created_By_Username","Created_On","E_mail","External_System","External_System_ID","Legacy_ID","License_Expiration_Date","License_Number","License_Status","Owner","Primary_Contact","Status","Supplier_Connection_ID__Workday_","Supplier_ID__Workday_","Tax_Exempt","Tax_Exempt_Number","Tax_ID_Number"];
//Hide
export const accountHideFields =["Address 1  ID","Address 1 Latitude","Account","Address 2  ID"];
export const contactHideFields =["Address 1  ID","Address 2  ID","Address 3  ID","Contact"];
export const locationHideFields =["Location"];
export const projectMesasureHideFields =["Contractor", "Contractor ID","Base Measure","Efficient Measure","Measure Configuration","Measure ID","Rebate Intake"];
export const projectHideFields =["Project","Rebate Intake"];
export const premiseHideFields =["Premise"];
export const invoiceDetailsHideFields =["Project","Invoice"];
export const activityDetailsHideFields =["Regarding","Task","Task Lead"];
export const utilityHideFields =["Utility Account"];
export const caseHideFields =["Case"];
export const issueHideFields =["Case","Issue"];
