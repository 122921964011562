
import React, { useEffect, useRef } from "react"
import $ from 'jquery'

import moment from "moment/moment"

const DataTableGrid = ({ props }) => {

    $.DataTable = require('datatables.net')
 
    const tableRef = useRef()
    //console.log(props);
    useEffect(() => {
        //  console.log(tableRef.current)
        const table = $(tableRef.current).DataTable(
            {
                data: props,
                 dom: '<"top"ip>rt<"bottom"ip>',
                columns: [
                    { title: 'First Name' },
                    { title: 'Last Name' },
                    { title: 'Business Unit' },
                    { title: 'User Status' },
                    { title: 'Rebate ID' },
                    { title: 'Rebate Viewed On',  render:function (value) {
                        //console.log(value);
                        return moment(value).format('MM/DD/YYYY, h:mm:ss a');;
                       // return moment(value).format('DD/MM/YYYY');
                    }
                   },
                 //   render: $(tableRef.current).DataTable.Moment("YYYY-MM-DDTHH:mm:ss.SSSZ", "DD/MM/YYYY")
                ],
                order: [[5, 'desc']],
                destroy: true  // I think some clean up is happening here
              
            }
        )
        // Extra step to do extra clean-up.
        return function () {
            //  console.log("Table destroyed")
            table.destroy()
        }
    }, [props])
    return (
        <div>
            <table className="display" width="100%" ref={tableRef}></table>
        </div>
    )
}

export default DataTableGrid;