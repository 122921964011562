import { BrowserRouter, Route, Routes, Link, Navigate, useLocation } from 'react-router-dom';
import Home from '../pages/Home'
import AuditLog from '../pages/AuditLog'
import UserRole from '../pages/UserRole'
import UserManagement from '../pages/UserManagement'
import Rebate from '../pages/Rebate'
import Account from '../pages/Account'
import Contact from '../pages/Contact';
import Location from '../pages/Location'
import Project from '../pages/Project'
import UtilityAccount from '../pages/UtilityAccount'
import Premise from '../pages/Premise'
import MeasureCalculations from '../pages/MeasureCalculations'
import Invoice from '../pages/Invoice'
import Activity from '../pages/Activity'
import Case from '../pages/Case'
import Documents from '../pages/Documents'
import MeasureDetails from '../pages/MeasureDetails'
import InvoiceDetails from '../pages/InvoiceDetails'
import CaseDetails from '../pages/CaseDetails'
import IssueDetails from '../pages/IssueDetails'
import ActivityDetails from '../pages/ActivityDetails'
import EfficientMeasure from '../pages/EfficientMeasure'
import BaseMeasure from '../pages/BaseMeasure'
import ExtentedMeasure from '../pages/ExtentedMeasure'
import AccessDenied from '../pages/AccessDenied'
import Contractor from '../pages/Contractor'
import ProjectExtendedDetails from '../pages/ProjectExtendedDetails';
import axios from "axios";
import * as Constants from "../utils/constant";
import React, { useEffect, useState, } from "react";

export const TopNavigation = () => {
    const [isAdmin, setAdmin] = useState(true);
    const location = useLocation();
    useEffect(() => {
        async function isAdmininstrator() {
            try {
                await axios.get(Constants._IS_CATALYSTADMIN)
                    .then((response) => {
                        var data = response.data;
                        setAdmin(data);
                        console.log(data);

                    })
                    .catch((error) => {
                        console.log(error);
                    });

            } catch (error) {
                console.log("error", error);
            }
        }
        isAdmininstrator();
    }, []);

    return (
        <>

            <ul className="nav nav-tabs container tabsmaxwidth" id="myTabs" role="tablist">
                <li className="nav-item" role="presentation">
                    <Link
                        className={`nav-link ${location.pathname === '/Home' ? 'active' : ''}`}
                        id="tab1"
                        data-bs-toggle="tab"
                        data-bs-target="#content1"
                        type="button"
                        role="tab"
                        aria-controls="content1"
                        aria-selected="true"
                        to="/Home"
                    >
                        Home
                    </Link>
                    <div className="underlinetab"></div>
                </li>
                {/* Repeat the pattern for other navigation links */}
                {isAdmin ? (
                    <>
                        <li className="nav-item" role="presentation">
                            <Link
                                className={`nav-link ${location.pathname === '/AuditLog' ? 'active' : ''}`}
                                id="tab2"
                                data-bs-toggle="tab"
                                data-bs-target="#content2"
                                type="button"
                                role="tab"
                                aria-controls="content2"
                                aria-selected="false"
                                to="/AuditLog"
                            >
                                Audit Log
                            </Link>
                            <div className="underlinetab"></div>
                        </li>

                        <li className="nav-item" role="presentation">
                            <Link
                                className={`nav-link ${location.pathname === '/UserManagement' ? 'active' : ''}`}
                                id="tab2"
                                data-bs-toggle="tab"
                                data-bs-target="#content4"
                                type="button"
                                role="tab"
                                aria-controls="content4"
                                aria-selected="false"
                                to="/UserManagement"
                            >
                                User Management
                            </Link>
                            <div className="underlinetab"></div>
                        </li></>
                ) : (<></>)}


            </ul>
            <div className="tabsdivider"></div>
            <div className="tab-content container maxwidth" id="myTabContent">
                <Routes>
                    <Route path="/" element={<Navigate replace to="/Home" />} />
                    <Route path='/Home' element={<Home />} ></Route>
                    <Route path='/AuditLog' element={<AuditLog />}></Route>
                    <Route path='/UserRole' element={<UserRole />}></Route>
                    <Route path='/UserManagement' element={<UserManagement />}></Route>
                    <Route path='/Rebate' element={<Rebate />}></Route>
                    <Route path='/Account' element={<Account />}></Route>
                    <Route path='/Contact' element={<Contact />}></Route>
                    <Route path='/Location' element={<Location />}></Route>
                    <Route path='/Project' element={<Project />}></Route>
                    <Route path='/UtilityAccount' element={<UtilityAccount />}></Route>
                    <Route path='/Premise' element={<Premise />}></Route>
                    <Route path='/MeasureCalculations' element={<MeasureCalculations />}></Route>
                    <Route path='/Invoice' element={<Invoice />}></Route>
                    <Route path='/Activity' element={<Activity />}></Route>
                    <Route path='/Case' element={<Case />}></Route>
                    <Route path='/Documents' element={<Documents />}></Route>
                    <Route path='/MeasureDetails' element={<MeasureDetails />}></Route>
                    <Route path='/InvoiceDetails' element={<InvoiceDetails />}></Route>
                    <Route path='/CaseDetails' element={<CaseDetails />}></Route>
                    <Route path='/IssueDetails' element={<IssueDetails />}></Route>
                    <Route path='/ActivityDetails' element={<ActivityDetails />}></Route>
                    <Route path='/EfficientMeasure' element={<EfficientMeasure />}></Route>
                    <Route path='/BaseMeasure' element={<BaseMeasure />}></Route>
                    <Route path='/ExtentedMeasure' element={<ExtentedMeasure />}></Route>
                    <Route path='/AccessDenied' element={<AccessDenied />}></Route>
                    <Route path='/Contractor' element={<Contractor />}></Route>
                    <Route path='/ProjectExtendedDetails' element={<ProjectExtendedDetails />}></Route>
                </Routes>
            </div>

        </>
    )
}
export default TopNavigation;