import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
 
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID,//2f156aad-ffd2-44a4-aa5c-1d82d593cdd7", // Client ID 
      authority: process.env.REACT_APP_AUTHORITY, //278ef9ed-35b2-4643-be74-d9beef4176c4 Tenant ID of the React.JS App Registration
      redirectUri:  process.env.REACT_APP_REDIRECT_URI,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            // case LogLevel.Info:
            //   console.info(message);
            //   return;
            // case LogLevel.Verbose:
            //   console.debug(message);
            //   return;
            // case LogLevel.Warning:
            //   console.warn(message);
            //   return;
            }
          },
        },
      },
    };
  
// Can be found in the API Permissions of the ASP.NET Web API
//scopes: ["api://16a1de9b-b660-4c56-be25-a9c08ddbde5d/api.scope"],
export const loginApiRequest = {
  scopes: [process.env.REACT_APP_SCOPE],
};