import React, { useEffect, useState } from "react";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import CatalystLoginPage from '../CatalystLoginPage'
import * as Constants from "../../utils/constant";
import axios from "axios";
import { useMsal, useAccount } from "@azure/msal-react";
export const AddUser = (props) => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [businessUnit, setBusinessUnit] = useState([]);
    const [add, setAdd] = useState(props.isNewMode);
    const [submiting, setSubmiting] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState({});
    const [inputFields, setInputFields] = useState({
        userId: 0,
        email: "",
        firstName: "",
        lastName: "",
        businessUnitId: "",
        isAdmin: false,
        isEnabled: false,
    });


    function afterOpenModal(e) {
        props.onAfterOpen(e, 'After Modal Opened');
    }

    function onModalClose(event) {
        let data = { name: 'example', type: 'closed from child' };
        props.onCloseModal(event, data);
    }
    useEffect(() => {
        async function getBusinessUnit() {
            setLoading(true);
            try {
                await axios.get(Constants._GET_ALL_BUSINESSUNIT)
                    .then(async (response) => {
                        console.log(response);
                        setBusinessUnit(response.data);
                        if (props.itemId != 0) {
                            await axios.get(Constants._GET_USER_BY_ID, {
                                params: {
                                    userId: props.itemId,
                                }
                            }).then((response) => {
                                var data = response.data;
                                if (data.length > 0) {
                                    console.log(data);
                                    setInputFields({
                                        ...inputFields, firstName: data[0].FirstName,
                                        businessUnitId: data[0].BusinessUnitId,
                                        email: data[0].Email,
                                        isAdmin: data[0].IsAdmin,
                                        isEnabled: data[0].IsEnabled,
                                        lastName: data[0].LastName,
                                        userId: data[0].Id,
                                    })
                                }
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        alert(error);
                    }).finally(
                        () => setLoading(false)
                    );
            } catch (error) {
                console.log("error", error);
                alert(error);
            }
        }
        getBusinessUnit();
    }, []);
    const validateValues = (inputValues) => {
        let errors = {};
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (inputValues.email.length == 0) {
            errors.email = "Email is empty.";
        }
        if (!emailRegex.test(inputValues.email)) {
            errors.email = "Please enter a valid email address.";
        }
        if (inputValues.firstName.length == 0) {
            errors.firstName = "First Name is empty.";
        }
        if (inputValues.lastName.length == 0) {
            errors.lastName = "Last Name is empty.";
        }
        if (inputValues.businessUnitId <= 0) {
            errors.businessUnit = "Please select Business Unit.";
        }
        return errors;
    };
    const handleEmailChange = (e) => {
        setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    };


    //Button Click Add & Update Users
    const handleSubmit = (event) => {
        event.preventDefault();
        setErrors(validateValues(inputFields));
        if (event.target.name == 'Add')
            setAdd(true);
        setSubmiting(true);
    };

    //Add Update user
    useEffect(() => {
        if (Object.keys(errors).length === 0 && submiting) {
            AddUpdateUser(props.isNewMode, props.editId);
            setSubmiting(false);
        }
    }, [errors]);

    async function AddUpdateUser(isAdd, editId) {
        const userInfo = JSON.stringify({
            "CurrentUserLoginId": account.username,
            "userId": editId,
            "firstName": inputFields.firstName,
            "lastName": inputFields.lastName,
            "email": inputFields.email,
            "isEnabled": inputFields.isEnabled,
            "isAdmin": inputFields.isAdmin,
            "businessUnitId": inputFields.businessUnitId,
            "businessUnit": ''
        });
        let customConfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        let result;
        if (isAdd) {
            result = await axios.post(Constants._ADD_USER, userInfo, customConfig);
        }
        else {
            result = await axios.post(Constants._UPDATE_USER, userInfo, customConfig);
        }
        let msg = {};
        msg.Status = result.data.isSuccess;
        msg.Message = result.data.Message;
        setMessage(msg);
        console.log(result.data.Message);

    }
    return (
        <div>
            <AuthenticatedTemplate>
                <div id="pop-content-user2" className="popup-user modaloverlay">
                    <div className="pop-up-form">
                        <div className="filter-column">
                            <div className="div-row">
                                {props.isNewMode ? (
                                    <h2>Add Employee Details</h2>) : (
                                    <h2>Update Employee Details</h2>)}

                            </div>
                            <div className="div-row">
                                <div className="user-label">
                                    User Email
                                </div>
                                <div className="user-txt">
                                    <input
                                        placeholder='User Email'
                                        name="email"
                                        type="email" required
                                        value={inputFields.email}
                                        readOnly={!props.isNewMode}
                                        onChange={handleEmailChange}></input>
                                    {errors.email ? (
                                        <p className="error">
                                            {errors.email}
                                        </p>
                                    ) : null}
                                </div>
                            </div>

                            <div className="div-row">
                                <div className="user-label">
                                    First Name
                                </div>
                                <div className="user-txt">
                                    <input
                                        placeholder='First Name'
                                        name="firstName"
                                        type="text" required
                                        value={inputFields.firstName}
                                        onChange={handleEmailChange}></input>

                                    {errors.firstName ? (
                                        <p className="error">
                                            {errors.firstName}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="div-row">
                                <div className="user-label">
                                    Last Name
                                </div>
                                <div className="user-txt">
                                    <input
                                        placeholder='Last Name'
                                        name="lastName"
                                        type="text" required
                                        value={inputFields.lastName}
                                        onChange={handleEmailChange}></input>
                                    {errors.lastName ? (
                                        <p className="error">
                                            {errors.lastName}
                                        </p>
                                    ) : null}
                                </div>
                            </div>


                            <div className="div-row">
                                <div className="user-label">Business Unit
                                </div>
                                <div className="user-txt">
                                    <div className="select-container-user">
                                        <select id="roles" className="select-options-user" value={inputFields.businessUnitId}
                                            onChange={e => setInputFields({ ...inputFields, businessUnitId: e.target.value })}>
                                            <option value="-1">Select Business Unit</option>
                                            {businessUnit.map(item => (
                                                <option
                                                    key={item.BusinessUnitId}
                                                    value={item.BusinessUnitId}
                                                >
                                                    {item.BusinessUnit}

                                                </option>
                                            ))}
                                        </select>
                                        <div className="arrow-container">
                                            <button id="arrowUp"><img src={require('../..//assets/images/up-arrow.svg').default} alt="Up" /></button>
                                            <button id="arrowDown"><img src={require('../..//assets/images/downarrow.svg').default} alt="Down" /></button>
                                        </div>
                                    </div>
                                    {errors.businessUnit ? (
                                        <p className="error">
                                            {errors.businessUnit}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="div-row">
                                <div className="user-label">Admin</div>
                                <div className="user-chk">
                                    <input type="checkbox" id="isAdmin" name="Admin" checked={inputFields.isAdmin}
                                        onChange={e => setInputFields({ ...inputFields, isAdmin: e.target.checked })} /></div>
                            </div>
                            <div className="div-row">
                                <div className="user-label">Enable User</div>
                                <div className="user-chk">
                                    <input type="checkbox" id="isEnabled" name="Enable User" checked={inputFields.isEnabled}
                                        onChange={e => setInputFields({ ...inputFields, isEnabled: e.target.checked })} /></div>
                            </div>
                        </div>
                        {message.Status ? (<div className="sucessmsg">
                            <p>{message.Message}</p>
                        </div>) : (<div className="error">
                            <p >{message.Message}</p>
                        </div>)}

                        {message.Status ? (<></>) : (
                            props.isNewMode ? (
                                <button className="popup-dwnd-btn-white" name="Add" onClick={handleSubmit}>Add</button>) : (
                                <button className="popup-dwnd-btn-white" name="Update" onClick={handleSubmit}>Update</button>)
                        )}
                        <button className="popup-dwnd-btn-white" onClick={e => onModalClose(e)}>Close</button>
                        {/* onClick={() => setIsOpen(false)} */}
                    </div>
                </div>
            </AuthenticatedTemplate >
            <UnauthenticatedTemplate>
                <CatalystLoginPage />
            </UnauthenticatedTemplate>
        </div >
    )
}

export default AddUser