import React, { useEffect, useRef } from "react"
import ReactDOM from 'react-dom';
import * as Constants from "../../utils/constant";
import $ from 'jquery'
import { useNavigate } from "react-router-dom";
import axios from "axios";
const SearchDataTable = ({ props }) => {
    $.DataTable = require('datatables.net');
    const navigate = useNavigate();
    const tableRef = useRef();

    const RedirectToDetails = async (redirectUrl, rbtId) => {

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: Constants._ADD_AUDIT_LOGS_API + rbtId,
            headers: {},
            data: ''
        };

        await axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            });
        navigate(redirectUrl);
    }
    useEffect(() => {
        const table = $(tableRef.current).DataTable(
            {
                data: props,
                searching: false,
                dom: '<"top"ip>rt<"bottom"ip>',
                columns: [
                    { title: 'Program' },
                    { title: 'Rebate ID' },
                    { title: 'Contact' },
                    { title: 'Account' },
                    { title: 'Location' },
                    { title: 'Project' },
                    {
                        title: 'Action',
                        // render: function (data, type, row, meta) {
                        //     if (type === "display") {
                        //         // data = '<Button onClick=OpenRebate(' + row[6] + ') className="action-button">View Rebate</Button >';
                        //         data  = '<a href=' +  row[6] + ' className="action-button">View Rebate</a>';
                        //     }
                        //     return data;
                        // }
                    }
                ],
                columnDefs: [
                    {
                        'targets': [6],
                        createdCell: (td, cellData, rowData, row, col) => {
                            ReactDOM.render(<button onClick={() => RedirectToDetails(rowData[6], rowData[1])} className="action-button">View Rebate</button>, td);
                        }
                    }
                ],
                destroy: true  // I think some clean up is happening here
            }
        )
        // Extra step to do extra clean-up.
        return function () {
            //  console.log("Table destroyed")
            table.destroy()
        }
    }, [props])
    return (
        <div>
            <table className="tabledata" width="100%" ref={tableRef}></table>
        </div>
    )
}
// async function OpenRebate(redirectUrl, rbtId) {
//      const navigate = useNavigate();
//     // const rbtInfo = JSON.stringify({
//     //     "rebateId": rebateId
//     // });
//     // let customConfig = {
//     //     headers: {
//     //         'Content-Type': 'application/json'
//     //     }
//     // };
//    // const rbt = { rebateId: rbtId };
//    // await axios.post(Constants._ADD_AUDIT_LOGS_API, rbt);

//     // await axios.post(Constants._ADD_AUDIT_LOGS_API, {
//     //     rebateId: rbtId
//     // })
//     //     .then((response) => {
//     //         console.log(response);
//     //     });

//       navigate(redirectUrl);


// }
export default SearchDataTable