import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "../../utils/constant";

const FetchCase = (props) => {
    const [items, setItems] = useState([]);
    const [header, setHeader] = useState([]);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        //console.log('i triggered once');
        getCase();
    }, []);

    async function getCase() {
        try {
            // console.log("props Service Call");
            setLoading(true);
            await axios.get(Constants._CASE_BY_REBATE_ID_API, {
                params: {
                    rebateId: props.rebateID,
                }
            })
                .then((response) => {
                    const result = []
                    var data = response.data;
                    // console.log('I got data');
                    if (data.length > 0) {
                        setItems(data);
                        setHeader(Object.keys(data[0]));
                        props.setCaseData(data);
                        // console.log('I got data');
                    }
                })
                .catch((error) => {
                    console.log(error);
                }).finally(
                    () => setLoading(false)
                );

        } catch (error) {
            console.log("error", error);
        }
    }


    // props.setMeasuresData(items);
    return (
        <>
            {isLoading && <div className="containerloader"><div id="loader" class="loader"></div></div>}
            {!isLoading && (
                (items.length > 0) ? (
                    <div className="tables-containers">
                        <FormatTable theadData={header} tbodyData={items} rID={props.rebateID} />
                    </div>) : (<div><p2>No Case Found</p2></div>)

            )}
        </>
    )
}
function FormatTable({ theadData, tbodyData, rID }) {
    return (
        <table id="Case_Calculations" className="Calculation-table">
            <thead>

                {theadData.map((heading, i) => {
                    return (heading == "Case") ? (<></>) : (<th key={'ms-h-' + i}>{heading.replaceAll("_", " ")}</th>)
                })}

            </thead>
            <tbody>
                {tbodyData.map((row, index) => {
                    return <tr key={index}>
                        {theadData.map((key, index) => {
                            return (
                                (key == "Case") ? (<></>) ://Exclude the Issue ID Column
                                    (key.toLowerCase() == "case_number") ? (<td key={'ms-r-' + index}>
                                        <a href={'/CaseDetails?CaseId='
                                            + encodeURI(row['Case'])
                                            + "&RebateId=" + rID} >{row[key]}</a>
                                    </td>)
                                        : (<td key={'ms-r-' + index}>{row[key]}</td>))
                        })}
                    </tr>;
                })}
            </tbody>
        </table>
    )
}
export default FetchCase