
    export const _SEARCH_API = '/RebateIntake/SearchRebate';
    export const _REBATE_BY_ID_API = '/RebateIntake/GetRebateByID';
    export const _REBATE_HOME_BY_ID_API = '/RebateIntake/GetRebateHomeByID';
    export const _REBATE_NOTES_BY_REBATE_ID_API = '/RebateIntake/GetNotes';
    export const _REBATE_HEADER_API = '/RebateIntake/GetRebateHeader';
    export const _ACCOUNT_BY_REBATE_ID_API = '/RebateIntake/GetAccountByRebateID';
    export const _CONTRACTOR_BY_ID_API = '/RebateIntake/GetContractorById';
    export const _CONTACT_BY_REBATE_ID_API = '/RebateIntake/GetContactByRebateID';
    export const _LOCATION_BY_REBATE_ID_API = '/RebateIntake/GetLocationByRebateID';
    export const _PROJECT_BY_REBATE_ID_API = '/RebateIntake/GetProjectByRebateID';
    export const _UTILITY_ACCOUNT_BY_REBATE_ID_API = '/RebateIntake/GetUtilityAccountByRebateID';
    export const _PREMISE_BY_REBATE_ID_API = '/RebateIntake/GetPremiseByRebateID';
    export const _All_MESAURE_BY_REBATE_API = '/RebateIntake/GetAllMesaureByRebateID';
    export const _EXPORT_BASE_MESASURE_BY_REBATE_API = '/RebateIntake/GetExportBaseMesasureByRebateID';
    export const _EXPORT_EFFICENT_MESASURE_BY_REBATE_API = '/RebateIntake/GetExportEfficentMesasureByRebateID';
    export const _EXPORT_EXTENTED_MESASURE_BY_REBATE_API = '/RebateIntake/GetExportExtentedDetailseByRebateID';
    export const _MESAURE_BY_REBATE_ID_API = '/RebateIntake/GetMesaureByRebateID';
    export const _MESAURE_DETAILS_BY_MEASURE_ID_API = '/RebateIntake/GetMesaureDetailsByMesaureId';
    export const _EXTENDED_DETAILS_BY_MEASURE_ID_API = '/RebateIntake/GetExtentedDetails';
    export const _EXTENDED_DETAILS_BY_PROJECT_ID_API = '/RebateIntake/GetProjectExtentedDetails';
    export const _EFFICIENT_MESAURE_DETAILS_BY_MEASURE_ID_API = '/RebateIntake/GetEfficientMeasureByProductID';
    export const _INVOICE_BY_REBATE_ID_API = '/RebateIntake/GetInvoiceByRebateID';
    export const _INVOICE_DETAILS_BY_REBATE_ID_API = '/RebateIntake/GetInvoiceDetailsByInVoiceId';
    export const _All_INVOICE_BY_REBATE_API = '/RebateIntake/GetAllInvoiceByRebateID';
    export const _All_CASE_BY_REBATE_API = '/RebateIntake/GetAllCaseByRebateID';
    export const _CASE_BY_REBATE_ID_API = '/RebateIntake/GetCaseByRebateID';
    export const _CASE_DETAILS_BY_CASE_ID_API = '/RebateIntake/GetCaseDetailsByCaseId';
    export const _ALL_ISSUE_BY_REBATE_ID_API = '/RebateIntake/GetAllCasesIssues';
    export const _ISSUE_BY_CASE_NUMBER_REBATE_ID_API = '/RebateIntake/GetIssuesByCaseNumber';
    export const _ISSUE_BY_CASE_ID_API = '/RebateIntake/GetIssueByCaseID';
    export const _ISSUE_DETAILS_BY_ISSUE_ID_API = '/RebateIntake/GetIssueDetailsByIssueID';
    export const _BULK_OPERATION_ACTIVITIES_ISSUE_ID_API = '/RebateIntake/GetBulkOperationActivities';
    export const _CAMPAIGN_ACTIVITY_ACTIVITIES_ISSUE_ID_API = '/RebateIntake/GetCampaignActivityActivities';
    export const _CAMPAIGN_RESPONSE_ACTIVITIES_ISSUE_ID_API = '/RebateIntake/GetCampaignResponseActivities';
    export const _Recurring_Appointment_Activities_ISSUE_ID_API = '/RebateIntake/GetRecurringAppointmentActivities';
    export const _SERVICE_APPOINTMENT_ACTIVITIES_ISSUE_ID_API = '/RebateIntake/GetServiceAppointmentActivities';
    export const _TASK_ACTIVITIES_ISSUE_ID_API = '/RebateIntake/GetTaskActivities';
    export const _QUOTE_CLOSE_ACTIVITIES_ISSUE_ID_API = '/RebateIntake/GetQuoteCloseActivities';
    export const _PHONE_CALL_ACTIVITIES_ISSUE_ID_API = '/RebateIntake/GetPhoneCallActivities';
    export const _ORDER_CLOSE_ACTIVITIES_ISSUE_ID_API = '/RebateIntake/GetOrderCloseActivities';
    export const _OPPORTUNITY_CLOSE_ACTIVITIES_ISSUE_ID_API = '/RebateIntake/GetOpportunityCloseActivities';
    export const _LETTER_ACTIVITIES_ISSUE_ID_API = '/RebateIntake/GetLetterActivities';
    export const _INCIDENT_RESOLUTION_ACTIVITIES_ISSUE_ID_API = '/RebateIntake/GetIncidentResolutionActivities';
    export const _FAX_ACTIVITIES_ISSUE_ID_API = '/RebateIntake/GetFaxActivities';
    export const _EMAIL_ACTIVITIES_ISSUE_ID_API = '/RebateIntake/GetEmailActivities';
    export const _APPOINTMENT_ACTIVITIES_ISSUE_ID_API = '/RebateIntake/GetAppointmentActivities';
    export const _ALL_ACTIVITIES_API = '/RebateIntake/GetAllActivitiesByRebateId';
    export const _ACTIVITIES_DETAILS_API = '/RebateIntake/GetActivitiesDetails';
    export const _ADD_AUDIT_LOGS_API = '/RebateIntake/AddAuditLogs?rebateId='
    export const _STORAGE_DOCUMENTS_API = '/Storage/GetRebateDocuments';
    export const _STORAGE_DOWNLOADZIP_API ='/Storage/DownloadZipFiles';
    export const _STORAGE_FILE = '/Storage/DownloadSingleFile/?filename=';
    export const  _REBATE_PAGE_URL = '/rebate';
    export const _GET_LOGS_USER =  "/Admin/GetLogsUsersName";
    export const _SEARCH_USER = "Admin/SearchUser";
    export const _ADD_USER = "/Admin/AddUser";
    export const _UPDATE_USER = "/Admin/UpdateUser";
    export const _GET_USER_LOGS =   "/Admin/GetUserLogs";
    export const _GET_ALL_BUSINESSUNIT =   "/Admin/GetAllBusinessUnit";
    export const _GET_ALL_USERS =   "/Admin/GetAllUsers";
    export const _GET_USER_BY_ID =   "/Admin/GetAllUsersById";
    export const _IS_CATALYSTADMIN = '/RebateIntake/IsCatalystAdmin';
   
